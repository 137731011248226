import React, { useMemo } from 'react';
import { CarrierWeightPricing as CarrierWeightPricingModel } from '../../../models/entities/admin/carrierWeightPricing';
import carriers from '../../../static/data/carriers-weight-pricing.json';
import FormFieldLabel from '../../shared/Form/FormFieldLabel';
import Grid from '../../shared/Grid/Grid';
import { DropDown } from '../../shared/inputs/base';
import { styled } from '../../shared/Theme/theme';
import ExcelReader from './components/ExcelReader';
import { RegionIdByName } from './enums/regions.enum';
import { getGridColumns } from './gridColumns';
type Props = {
    carrierWeightPricing: CarrierWeightPricingModel[];
    regionsInData: string[];
    selectedCarrierCode: string;
    setSelectedCarrierCode: (carrierCode: string) => void;
    updateCarrierWeightPricing: (carrierWeightPricing: CarrierWeightPricingModel[]) => void;
};
const CarrierWeightPricing = ({
    carrierWeightPricing,
    selectedCarrierCode,
    setSelectedCarrierCode,
    updateCarrierWeightPricing,
    regionsInData
}: Props) => {
    const carriersDropDownOptionsByShipmentType = useMemo(
        () =>
            carriers.map((c) => ({
                text: c.carrierName,
                value: c.carrierCode
            })),
        []
    );
    const updateSelectedCarrierCode = (carrierCode: string) => {
        setSelectedCarrierCode(carrierCode);
    };
    return (
        <PageWrapper>
            <ButtonWrapper>
                <div style={{ width: '200px' }}>
                    <FormFieldLabel text="Carrier" />
                    <DropDown
                        placeHolder={'Select Carrier'}
                        options={carriersDropDownOptionsByShipmentType}
                        onChange={updateSelectedCarrierCode}
                        // showSearch
                    />
                </div>
                {selectedCarrierCode && (
                    <div>
                        <ExcelReader updateCarrierWeightPricing={updateCarrierWeightPricing} selectedCarrierCode={selectedCarrierCode} />
                    </div>
                )}
            </ButtonWrapper>
            {selectedCarrierCode && carrierWeightPricing.length ? (
                <GridWrapper>
                    <Grid<CarrierWeightPricingModel>
                        onRowMoreOptionsClick={(row) => console.log(row)}
                        columns={getGridColumns(regionsInData)}
                        data={carrierWeightPricing}
                        idKey="weight"
                    />
                </GridWrapper>
            ) : null}
            {!selectedCarrierCode && (
                <CenterContainer>
                    <FormFieldLabel text="Please select a carrier to view the pricing" />
                </CenterContainer>
            )}
        </PageWrapper>
    );
};

export default CarrierWeightPricing;

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: overlay;
    height: 100%;
    width: 100%;
    z-index: 1;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;
const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    `;
