import React from 'react';
import { connect } from 'react-redux';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import claimsType from '../../../../models/entities/claimsType';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { RootState } from '../../../../state/store/store';
import { claimsDomainsType } from '../../../../userClaims';
import { DropDown } from '../../../shared/inputs/base';
import renderStateOption from '../../../shared/SmallComponents/StateOption';

type Props = {
    updateState: (state: string) => Promise<void>;
    featureFlags?: featureFlagsModel;
    localizations?: any;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    settingsState?: [{ code: string; name: string; description: string; displayOrder: number; color: string; toolTipText?: string }];
};

const ShipmentStateSelect = ({ updateState, featureFlags, localizations, getUserClaims, settingsState }: Props) => {
    return (
        <>
            <DropDown
                placeHolder={localizations.shipment_state}
                options={
                    settingsState
                        ?.sort((a, b) => a.displayOrder - b.displayOrder)
                        .map((item) => ({
                            text: item.name,
                            value: item.code,
                            color: item.color
                        })) || []
                }
                optionRenderer={renderStateOption}
                onChange={async (value: string) => {
                    await updateState(value);
                }}
                disabled={featureFlags?.UNDER_FORWARDER || !getUserClaims('shipment').edit_state}
                verifyChange={{
                    title: (value: string) => `${localizations.you_are_moving_the_shipment_state_to} ${
                        settingsState?.find((item) => item.code === value)?.name
                    }.
                                    ${localizations.are_you_sure}`
                }}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    isFreelancerOrForwarder: userSelectors.isFreelancerOrForwarder(state),
    featureFlags: userSelectors.featureFlags(state),
    localizations: localizationSelectors.singleShipmentPage(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStateSelect);
