import { createEditorStateWithText } from '@draft-js-plugins/editor';
import { EditorState } from 'draft-js';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { businessPartner, settings } from '../../../../../models/entities/businessPartner/businessPartner';
import { ePaymentTerms } from '../../../../../models/entities/businessPartner/paymentTerms';
import claimsType from '../../../../../models/entities/claimsType';
import { allowedCompany } from '../../../../../models/entities/user';
import { businessPartnersSelectors } from '../../../../../state/ducks/businessPartner/selectors';
import { localizationSelectors } from '../../../../../state/ducks/localization/selectors';
import { userSelectors } from '../../../../../state/ducks/user/selectors';
import { userDetails } from '../../../../../state/ducks/users/users';
import { RootState } from '../../../../../state/store/store';
import { claimsDomainsType } from '../../../../../userClaims';
import { emailPattern } from '../../../../../utils/patterns';
import { convertEditorStateToHtml, convertHtmlToEditorState } from '../../../../../utils/textEditorUtils';
import { enumToDropdownOptions, Option } from '../../../../shared/inputs/base/DropDown';
import TextEditor from '../../../../shared/inputs/base/TextEditor';
import { CurrencyInput, FormCheckbox, FormDropDown, FormTextInput, UserDropDownForm } from '../../../../shared/inputs/form';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/SmallComponents/Button';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import Loader from '../../../../shared/SmallComponents/Loader';
import { styled } from '../../../../shared/Theme/theme';

export enum formMode {
    NEW,
    EDIT
}

interface formFields {
    name?: string;
    type?: string;
    address?: string;
    phone_number?: string;
    email?: string;
    contact_name?: string;
    vat_number?: string;
    website?: string;
    companyId?: string;
    paymentTerms?: Array<string>;
    paymentTermsRemarks?: string;
    taxesDuties?: boolean;
    remarksAlert?: boolean;
    monthlyIntrest?: number;
    creditLine?: number;
    creditLineCurrency: string;
    remarks: EditorState;
    changestatealertvalues?: Array<string>;
    salesman?: Array<string>;
}
const requiredFields = ['name', 'type', 'vat_number'];
type Props = {
    party?: businessPartner;
    type?: string;
    businessPartnerVatNumbers?: string[];
    mode: formMode;
    localizations?: any;
    settings?: settings;
    submitCallback?: (businessPartner: businessPartner) => void;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createBusinessPartner: (party: businessPartner) => Promise<businessPartner>;
    updateBusinessPartner: (businessPartnerId: string, party: businessPartner) => Promise<number>;
    createError?: string;
    allowedCompanies: allowedCompany[];
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

export const partiesToDropdownOptions = (parties: Array<businessPartner> | undefined): Array<Option> => {
    if (!parties) return [];
    return parties.map((party) => ({
        text: party.name,
        value: '' + party.id
    }));
};

const BusinessPartnerFormConnected = ({
    party,
    type,
    localizations,
    mode,
    openedFromModal,
    businessPartnerVatNumbers,
    createBusinessPartner,
    updateBusinessPartner,
    confirmation,
    submitCallback,
    createError,
    settings,
    allowedCompanies,
    getUserClaims
}: Props) => {
    const [submitLoading, setLoading] = useState(false);
    const emptyEditorState = useRef(createEditorStateWithText(''));

    const partyTypesOptions = !getUserClaims('admin').view
        ? settings?.partyTypes
              ?.map((item) => ({
                  text: item.name,
                  value: item.code
              }))
              .filter((data) => data.text != 'Cargo Owner')
        : settings?.partyTypes?.map((item) => ({
              text: item.name,
              value: item.code
          }));

    const stateTypesOptions = settings?.state?.map((item) => ({
        text: item.name,
        value: item.code
    }));
    const initFormValues: formFields = {
        name: '',
        type: '',
        address: '',
        phone_number: '',
        email: '',
        vat_number: '',
        website: '',
        companyId: '',
        paymentTerms: [],
        paymentTermsRemarks: '',
        taxesDuties: false,
        remarksAlert: false,
        monthlyIntrest: 0,
        creditLine: 0,
        creditLineCurrency: 'USD',
        remarks: emptyEditorState.current,
        changestatealertvalues: [],
        salesman: []
    };
    const setInitialValues = () => {
        if (!party || mode === formMode.NEW) {
            initFormValues.type = type;
            return initFormValues;
        } else {
            initFormValues.name = party.name;
            initFormValues.type = party.type;
            initFormValues.phone_number = party.phoneNumber;
            initFormValues.email = party.email;
            initFormValues.vat_number = party.vatNumber;
            initFormValues.website = party.website || '';
            initFormValues.companyId = party.companyId;
            initFormValues.paymentTerms = party.paymentTerms ? [party.paymentTerms] : undefined;
            initFormValues.paymentTermsRemarks = party.paymentTermsRemarks;
            initFormValues.taxesDuties = party.taxesDuties;
            initFormValues.remarksAlert = party.remarksAlert;
            initFormValues.monthlyIntrest = party.monthlyIntrest;
            initFormValues.creditLine = party.creditLine;
            initFormValues.creditLineCurrency = party.creditLineCurrency;
            initFormValues.remarks = convertHtmlToEditorState(
                party.remarks,
                (id: string) => undefined,
                (id: string) => undefined,
                undefined
            );
            initFormValues.changestatealertvalues = party.changestatealertvalues?.split(',');
            initFormValues.salesman = party.salesman?.split(',');
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }

        const errors: any = {};
        //TODO : add validation of vat number.(unique per company id)
        requiredFields.forEach((field) => {
            if (!values[field]) errors[field] = localizations.parties.form.required_error;
        });
        //if (values.phone_number && !values.phone_number.match(phonePattern)) errors.phone_number = localizations.parties.form.phone_error;
        if (values.email && !values.email.match(emailPattern)) errors.email = localizations.parties.form.email_error;

        const maxLengthValidation = (field: string, maxLength: number, message: string) => {
            if (Array.isArray(values[field]) && values[field].length > 0) {
                if (values[field][0] && values[field][0].length > maxLength) errors[field] = message;
            } else if (values[field] && values[field].length > maxLength) {
                errors[field] = message;
            }
        };

        let vatNumbers = businessPartnerVatNumbers;

        if (party && vatNumbers) {
            const currenntVatIndex = vatNumbers.indexOf(party.vatNumber);
            if (currenntVatIndex !== -1) {
                vatNumbers?.splice(currenntVatIndex, 1);
            }
        }

        if (vatNumbers?.includes(values.vat_number)) errors.vat_number = localizations.parties.form.vat_number_error;

        maxLengthValidation('name', 100, localizations.parties.form.name_max_length_error);
        maxLengthValidation('vat_number', 20, localizations.parties.form.vat_number_max_length_error);
        maxLengthValidation('phone_number', 40, localizations.parties.form.phone_max_length_error);
        maxLengthValidation('email', 100, localizations.parties.form.email_max_length_error);
        maxLengthValidation('website', 50, localizations.parties.form.website_max_length_error);
        maxLengthValidation('paymentTerms', 25, localizations.parties.form.payment_terms_max_length_error);
        maxLengthValidation('paymentTermsRemarks', 150, localizations.parties.form.payment_terms_remarks_max_length_error);

        return errors;
    };
    const [users] = useState<Array<userDetails>>();
    const submit = (values: any) => {
        const payload = {
            name: values.name,
            type: values.type,
            phoneNumber: values.phone_number,
            email: values.email,
            vatNumber: values.vat_number,
            website: values.website,
            companyId: values.companyId,
            paymentTerms: values.paymentTerms?.length > 0 ? values.paymentTerms[0] : null,
            paymentTermsRemarks: values.paymentTermsRemarks,
            taxesDuties: values.taxesDuties,
            remarksAlert: values.remarksAlert,
            monthlyIntrest: Number(values.monthlyIntrest),
            creditLine: Number(values.creditLine),
            creditLineCurrency: values.creditLineCurrency,
            remarks: convertEditorStateToHtml(values.remarks),
            changestatealertvalues: values.changestatealertvalues?.toString(),
            salesman: values.salesman?.toString()
        } as businessPartner;
        if (mode === formMode.NEW) {
            setLoading(true);
            createBusinessPartner(payload)
                .then((value: businessPartner) => {
                    if (submitCallback) submitCallback(value);
                    if (openedFromModal && value) {
                        openedFromModal.setVisible(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (mode === formMode.EDIT && party) {
            setLoading(true);
            updateBusinessPartner(party.id, payload)
                .then((success: number) => {
                    if (submitCallback) submitCallback(payload);
                    if (openedFromModal && success) {
                        openedFromModal.setVisible(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values, setFieldValue }) => {
                return (
                    <Form>
                        <Container>
                            {allowedCompanies.length > 1 && (
                                <>
                                    <FieldLabel>{localizations.parties.form.partycompany_placeholder}</FieldLabel>
                                    <FieldContainer>
                                        <FormDropDown
                                            name="companyId"
                                            placeHolder={localizations.parties.form.partycompany_placeholder}
                                            options={allowedCompanies.map((item) => {
                                                return { value: item.companyId, text: item.companyName };
                                            })}
                                        />
                                    </FieldContainer>
                                </>
                            )}
                            <Flex>
                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.partyname_placeholder}</FieldLabel>
                                    <FormTextInput name="name" placeHolder={localizations.parties.form.partyname_placeholder} />
                                </FieldContainer>

                                {!type && (
                                    <>
                                        <FieldContainer>
                                            <FieldLabel>{localizations.parties.form.partytype_placeholder}</FieldLabel>
                                            <FormDropDown
                                                name="type"
                                                options={partyTypesOptions || []}
                                                placeHolder={localizations.parties.form.partytype_placeholder}
                                                showSearch
                                            />
                                        </FieldContainer>
                                    </>
                                )}
                            </Flex>
                            <Flex>
                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.partyvat_number_placeholder}</FieldLabel>
                                    <FormTextInput name="vat_number" placeHolder={localizations.parties.form.partyvat_number_placeholder} />
                                </FieldContainer>
                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.partyphone_placeholder}</FieldLabel>
                                    <FormTextInput name="phone_number" placeHolder={localizations.parties.form.partyphone_placeholder} />
                                </FieldContainer>
                            </Flex>
                            <Flex>
                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.partyemail_placeholder}</FieldLabel>
                                    <FormTextInput name="email" placeHolder={localizations.parties.form.partyemail_placeholder} />
                                </FieldContainer>
                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.partywebsite_placeholder}</FieldLabel>
                                    <FormTextInput name="website" placeHolder={localizations.parties.form.partywebsite_placeholder} />
                                </FieldContainer>
                            </Flex>
                            <Flex>
                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.payment_terms}</FieldLabel>
                                    <FormDropDown
                                        name="paymentTerms"
                                        options={enumToDropdownOptions(ePaymentTerms)}
                                        placeHolder={localizations.parties.form.payment_terms}
                                        showSearch
                                        mode="tags"
                                        onChange={(arr: Array<string>) => {
                                            if (arr.length > 0) setFieldValue('paymentTerms', [arr.pop()]);
                                        }}
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.payment_terms_remarks}</FieldLabel>
                                    <FormTextInput name="paymentTermsRemarks" placeHolder={localizations.parties.form.payment_terms_remarks} />
                                </FieldContainer>
                            </Flex>
                            <Flex>
                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.credit_line}</FieldLabel>
                                    <CurrencyInput
                                        currencyValue={values.creditLineCurrency}
                                        numberInputName="creditLine"
                                        numberPlaceholder={localizations.parties.form.credit_line}
                                        unitInputName="creditLineCurrency"
                                    />
                                </FieldContainer>

                                <FieldContainer>
                                    <FieldLabel>{localizations.parties.form.monthly_intrest}</FieldLabel>
                                    <FormTextInput
                                        name="monthlyIntrest"
                                        placeHolder={localizations.parties.form.monthly_intrest}
                                        type="number"
                                        min="0"
                                        step="0.01"
                                    />
                                </FieldContainer>
                            </Flex>
                            <Flex>
                                {!type && (
                                    <>
                                        <FieldContainer>
                                            <FieldLabel>{localizations.parties.form.updatestates_placeholder}</FieldLabel>
                                            <FormDropDown
                                                name="changestatealertvalues"
                                                mode="multiple"
                                                options={stateTypesOptions || []}
                                                placeHolder={localizations.parties.form.updatestates_placeholder}
                                                showSearch
                                            />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <FieldLabel>{localizations.parties.form.salesman_placeholder}</FieldLabel>
                                            <UserDropDownForm
                                                name="salesman"
                                                placeHolder={localizations.parties.form.salesman_placeholder}
                                                users={users}
                                                customUsers
                                                mode="multiple"
                                                showSearch
                                            />
                                        </FieldContainer>
                                    </>
                                )}
                            </Flex>
                            <FieldContainer>
                                <div style={{ display: 'flex' }}>
                                    <FieldLabel>{localizations.parties.form.remarks_placeholder}</FieldLabel>
                                    <FormCheckbox style={{ marginLeft: '100px' }} name="remarksAlert">
                                        {localizations.parties.form.remarks_alert}
                                    </FormCheckbox>
                                </div>
                                <TextEditor
                                    id="remarks-text-editor"
                                    editorState={values.remarks}
                                    onChange={(state: EditorState) => {
                                        setFieldValue('remarks', state);
                                    }}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormCheckbox name="taxesDuties">{localizations.parties.form.taxes_duties}</FormCheckbox>
                            </FieldContainer>
                            <Error>{createError}</Error>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                <Button minWidth="55px" onClick={() => openedFromModal.setVisible(false)}>
                                    <span>{localizations.parties.modal.cancel_btn}</span>
                                </Button>
                                <Button minWidth="55px" buttonType="filled" type="submit">
                                    {submitLoading ? (
                                        <Loader width="20px" marginTop="0px" showText={false} />
                                    ) : (
                                        <span>
                                            {mode === formMode.NEW ? localizations.parties.modal.add_btn : localizations.parties.modal.edit_btn}
                                        </span>
                                    )}
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};
const FieldContainer = styled.div`
    margin-bottom: 12px;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
`;
const Error = styled.div`
    width: 300px;
    color: red;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Flex = styled.div`
    display: flex;
    > * {
        margin-right: 10px;
        flex-basis: 50%;
    }
`;

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.singleShipmentPage(state),
    createError: state.error.effects.businessPartners.createBusinessPartner,
    settings: businessPartnersSelectors.settingsByLanguage(state),
    businessPartnerVatNumbers: businessPartnersSelectors.businessPartnerVatNumbers(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: any) => ({
    createBusinessPartner: async (party: businessPartner) => dispatch.businessPartners.createBusinessPartner(party),
    updateBusinessPartner: async (businessPartnerId: string, party: businessPartner) =>
        dispatch.businessPartners.updateBusinessPartner({ businessPartnerId, party })
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPartnerFormConnected);
