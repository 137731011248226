import { ModelConfig } from '@rematch/core';
import { IFollowUpExtended } from '../../../components/pages/Followup/FollowUp';
import { localSDK as client } from '../../../sdk';
import { FollowUp } from '../../../models/entities/shipment/shipment';

export type FollowUpState = {
    followUps: IFollowUpExtended[];
    showAllFollowUps: boolean;
    lastVisitShipmentId?: string;
};

export const followUps: ModelConfig<FollowUpState> = {
    state: {
        followUps: [],
        showAllFollowUps: false
    },
    reducers: {
        setFollowUps(state: FollowUpState, followUps: IFollowUpExtended[]): FollowUpState {
            return { ...state, followUps };
        },
        updateShowAllFollowUps(state: FollowUpState, showAllFollowUps: boolean): FollowUpState {
            return { ...state, showAllFollowUps };
        },
        setLastVisitShipmentId(state: FollowUpState, shipmentId: string): FollowUpState {
            return { ...state, lastVisitShipmentId: shipmentId };
        }
    },
    effects: (dispatch: any) => ({
        async getFollowUps(_: any, state: any) {
            const followUps = await client.followUp().getFollowUps(state.followUps.showAllFollowUps);
            dispatch.followUps.setFollowUps(followUps);
        },

        async addNewFollowUp({
            shipmentId,
            followUp
        }: {
            shipmentId: string;
            followUp: any; // Replace with proper FollowUpModel type
        }) {
            await client.followUp().addNewFollowUp(shipmentId, followUp);
            // Refresh follow-ups list after adding
            dispatch.followUps.getFollowUps();
        },

        async updateFollowUpCompletionStatus({
            shipmentId,
            followUpId,
            isCompleted
        }: {
            shipmentId: string;
            followUpId: number;
            isCompleted: boolean;
        }) {
            await client.followUp().updateFollowUpCompletionStatus(shipmentId, followUpId, isCompleted);
            // Refresh follow-ups list after updating
            dispatch.followUps.getFollowUps();
        },

        async deleteFollowUp({ shipmentId, followUpId }: { shipmentId: string; followUpId: number }) {
            await client.followUp().deleteFollowUp(shipmentId, followUpId);
            // Refresh follow-ups list after deleting
            dispatch.followUps.getFollowUps();
        },
        async updateFollowUp({
            shipmentId,
            followUp
        }: {
            shipmentId: string;
            followUp: Partial<FollowUp>; // Replace with proper FollowUpModel type
        }) {
            await client.followUp().updateFollowUp(shipmentId, followUp);
            // Refresh follow-ups list after updating
            dispatch.followUps.getFollowUps();
        },
        async setShowAllFollowUps(showAllFollowUps: boolean) {
            await dispatch.followUps.updateShowAllFollowUps(showAllFollowUps);
            dispatch.followUps.getFollowUps();
        }
    })
};
