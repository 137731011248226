import { createSelector } from 'reselect';
import { RootState } from '../../store/store';
import { RegionNameById } from '../../../components/pages/CarrierWeightPricing/enums/regions.enum';
import { groupBy, uniq } from 'lodash';

const carrierWeightPricing = (state: RootState) => state.carrierWeightPricing.carrierWeightPricing;
const selectedCarrierCode = (state: RootState) => state.carrierWeightPricing.seletedCarrierCode;

const getRegionTransformerPricing = createSelector(carrierWeightPricing, (carrierWeightPricing) => {
    const weights = uniq(carrierWeightPricing.map((c) => c.weight));
    const groupedArr = groupBy(carrierWeightPricing, 'weight');
    const responseArr = [];
    for (const weight of weights) {
        const response: any = {
            weight: weight,
            carrierCode: carrierWeightPricing[0].carrierCode
        };
        groupedArr[weight].forEach((c) => {
            // Use the numeric code from the backend (c.region) to get the string name.
            const regionName = RegionNameById[c.region];
            response[regionName] = c.price;
        });
        responseArr.push(response);
    }
    return responseArr;
});
const regionsInData = createSelector(carrierWeightPricing, (carrierWeightPricing) => {
    return uniq(carrierWeightPricing.map((c) => RegionNameById[c.region]));
});
const selectors = {
    carrierWeightPricing,
    selectedCarrierCode,
    getRegionTransformerPricing,
    regionsInData
};

export default selectors;