import React from 'react';
import { settings, shipment, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { RootState } from '../../../../state/store/store';
import { connect } from 'react-redux';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { formMode } from '../../commonSubEntities/events/EventForm/EventFormConnected';
import { billingLine } from '../../../../models/entities/common_subentities/billingLine';
import AccountingFormConnected from '../../commonSubEntities/accounting/AccountingForm/AccountingFormConnected';

type Props = {
    shipment?: shipment;
    billingLine?: billingLine;
    mode: formMode;
    localization?: any;
    settings: shipmentSettingsFunc;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createShipmentBillingLine: (shipmentNumber: string, billingLine: billingLine) => void;
    updateShipmentBillingLine: (shipmentNumber: string, billingLine: billingLine) => void;
    showLoader?: boolean;
    UserCompanyID?: string;
};

const ShipmentAccountingFormConnected = ({
    shipment,
    billingLine,
    localization,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createShipmentBillingLine,
    updateShipmentBillingLine,
    showLoader
}: Props) => {
    return (
        <AccountingFormConnected<shipment, settings>
            entity={shipment}
            billingLine={billingLine}
            localization={localization}
            mode={mode}
            settings={settings(shipment)}
            openedFromModal={openedFromModal}
            confirmation={confirmation}
            createBillingLine={createShipmentBillingLine}
            updateBillingLine={updateShipmentBillingLine}
            showLoader={showLoader}
            weight={parseFloat(shipment?.weight?.toString() || '0')}
            volume={parseFloat(shipment?.volume?.toString() || '0')}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localization: localizationSelectors.singleShipmentPage(state),
    settings: shipmentsSelectors.settingsByLanguage(state),
    shipment: singleShipmentSelectors.shipment(state),
    showLoader: state.loading.effects.shipments.createShipmentBillingLine || state.loading.effects.shipments.updateShipmentBillingLine,
    userInfo: userSelectors.userInfo(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    createShipmentBillingLine: (shipmentNumber: string, billingLine: billingLine) =>
        dispatch.shipments.createShipmentBillingLine({ shipmentNumber, billingLine }),
    updateShipmentBillingLine: (shipmentNumber: string, billingLine: billingLine) =>
        dispatch.shipments.updateShipmentBillingLine({ shipmentNumber, billingLine })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentAccountingFormConnected);
