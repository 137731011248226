import BaseController from '..';
import { CarrierWeightPricing } from '../../../models/entities/admin/carrierWeightPricing';
import ICarrierWeightPricing from './ICarrierWeightPricing';

export default class CarrierWeightPricingController extends BaseController implements ICarrierWeightPricing {
    constructor(baseUrl: string) {
        super(baseUrl);
    }

    async fetchCarrierWeightPricing(carrierCode: string): Promise<Array<CarrierWeightPricing>> {
        const response = await this.client.get('/companyAdmin/carrier-weight-pricing/carrierCode/' + carrierCode);
        return response;
    }
    async updateCarrierWeightPricing(data: CarrierWeightPricing[]): Promise<Array<CarrierWeightPricing>> {
        const response = await this.client.put('/companyAdmin/carrier-weight-pricing/bulk', JSON.stringify(data));
        return response;
    }
}
