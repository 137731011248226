import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DropDown } from '.';
import { userDropDown, userInfo } from '../../../../models/entities/user';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { usersSelectors } from '../../../../state/ducks/users/selectors';
import { userDetails } from '../../../../state/ducks/users/users';
import { RootState } from '../../../../state/store/store';
import { UserImageAvatar } from '../../Layout/Header/AvatarHeader';
import HexagonAvatar from '../../SmallComponents/HexagonAvatar';
import Loader from '../../SmallComponents/Loader';
import { styled } from '../../Theme/theme';
import { Option } from './DropDown';
import { MetaShipment } from '../../../../state/ducks/shipments/shipments';

type Props = {
    metaShipments: MetaShipment[];
    onChange?: any;
    onBlur?: any;
    value: string | string[] | undefined;
    hasError?: boolean;
    placeHolder?: string;
    showSearch?: boolean;
    mode?: 'multiple' | 'tags';
};

const ShipmentDropDownBase = ({ metaShipments, ...restProps }: Props) => {
    const renderShipmentOption = (option: Option) => {
        return (
            <OptionContainer>
                <div style={{ marginLeft: '5px', marginRight: '6px', color: '#394372', fontWeight: 500 }}>{option.text}</div>
            </OptionContainer>
        );
    };

    return (
        <Container>
            <DropDown
                {...restProps}
                options={
                    metaShipments?.map((item) => {
                        return { value: item.id, text: item.CargoZoneNumber };
                    }) || []
                }
                optionRenderer={renderShipmentOption}
            />
            {!metaShipments && (
                <LoaderWrapper>
                    <Loader marginTop="0" showText={false} width="20px" />
                </LoaderWrapper>
            )}
        </Container>
    );
};
const Container = styled.div`
    display: flex;
    align-items: center;
`;
const LoaderWrapper = styled.div`
    margin-left: 10px;
`;
const OptionContainer = styled.div`
    display: flex;
    align-items: center;
`;
export default ShipmentDropDownBase;
