import { allShipmentsPage } from './allShipmentsPage/allShipmentsPage';
import { authentication } from './authentication/authentication';
import { localization } from './localization/localization';
import { shipments } from './shipments/shipments';
import { singleShipment } from './singleShipment/singleShipment';
import { dashboard } from './dashboard/dashboard';
import { tasks } from './tasks/tasks';
import { businessPartners } from './businessPartner/businessPartner';
import { allBusinessPartnersPage } from './allBusinessPartnersPage/allBusinessPartnersPage';
import { singleBusinessPartner } from './singleBusinessPartner/singleBusinessPartner';
import { user } from './user/user';
import { notifications } from './notifications/notifications';
import { header } from './header/header';
import { users } from './users/users';
import { loadingNotify } from './loading_notify/loading_notify';
import { admin } from './admin/admin';
import { companies } from './companies/companies';
import { purchaseOrders } from './purchaseOrders/purchaseOrders';
import { singlePurchaseOrder } from './singlePurchaseOrder/singlePurchaseOrder';
import { allPurchaseOrdersPage } from './allPurchaseOrdersPage/allPurchaseOrdersPage';
import { purchaseDemands } from './purchaseDemands/purchaseDemands';
import { singlePurchaseDemand } from './singlePurchaseDemand/singlePurchaseDemand';
import { allPurchaseDemandsPage } from './allPurchaseDemandsPage/allPurchaseDemandsPage';
import { bids } from './bids/bids';
import { singleBid } from './singleBid/singleBid';
import { pagesHistory } from './pagesHistory/pagesHistory';
import { chats } from './chats/chats';
import { containers } from './containers/containers';
import { products } from './products/products';
import { inventory } from './inventory/inventory';
import { singleCompany } from './singleCompany/singleCompany';
import { categories } from './categories/categories';
import { companiesRelations } from './companiesRelations/companiesRelations';
import { userRegistration } from './userRegistration/userRegistration';
import { application } from './application/application';
import { internalFlowInventoryLogs } from './internalFlowInventoryLogs/internalFlowInventoryLogs';
import { orders } from './orders/orders';
import { tariffs } from './tariffs/tariffs';
import { singleTariff } from './singleTariff/singleTariff';
import { singleOrder } from './singleOrder/singleOrder';
import { carrierWeightPricing } from './carrierWeightPricing/carrierWeightPricing';
import { followUps } from './followUps/followUps';

export interface RootModel {
    allShipmentsPage: typeof allShipmentsPage;
    authentication: typeof authentication;
    localization: typeof localization;
    shipments: typeof shipments;
    singleShipment: typeof singleShipment;
    dashboard: typeof dashboard;
    tasks: typeof tasks;
    businessPartners: typeof businessPartners;
    allBusinessPartnersPage: typeof allBusinessPartnersPage;
    singleBusinessPartner: typeof singleBusinessPartner;
    user: typeof user;
    notifications: typeof notifications;
    header: typeof header;
    users: typeof users;
    loadingNotify: typeof loadingNotify;
    admin: typeof admin;
    companies: typeof companies;
    purchaseOrders: typeof purchaseOrders;
    singlePurchaseOrder: typeof singlePurchaseOrder;
    allPurchaseOrdersPage: typeof allPurchaseOrdersPage;
    purchaseDemands: typeof purchaseDemands;
    singlePurchaseDemand: typeof singlePurchaseDemand;
    allPurchaseDemandsPage: typeof allPurchaseDemandsPage;
    bids: typeof bids;
    singleBid: typeof singleBid;
    pagesHistory: typeof pagesHistory;
    chats: typeof chats;
    containers: typeof containers;
    products: typeof products;
    inventory: typeof inventory;
    singleCompany: typeof singleCompany;
    categories: typeof categories;
    companiesRelations: typeof companiesRelations;
    userRegistration: typeof userRegistration;
    application: typeof application;
    internalFlowInventoryLogs: typeof internalFlowInventoryLogs;
    orders: typeof orders;
    tariffs: typeof tariffs;
    singleTariff: typeof singleTariff;
    singleOrder: typeof singleOrder;
    carrierWeightPricing : typeof carrierWeightPricing;
    followUps: typeof followUps;
}

export const models: RootModel = {
    allShipmentsPage,
    authentication,
    localization,
    shipments,
    singleShipment,
    dashboard,
    tasks,
    businessPartners,
    allBusinessPartnersPage,
    singleBusinessPartner,
    user,
    notifications,
    header,
    users,
    loadingNotify,
    admin,
    purchaseOrders,
    singlePurchaseOrder,
    allPurchaseOrdersPage,
    purchaseDemands,
    singlePurchaseDemand,
    allPurchaseDemandsPage,
    bids,
    singleBid,
    pagesHistory,
    chats,
    containers,
    products,
    inventory,
    companies,
    singleCompany,
    categories,
    companiesRelations,
    userRegistration,
    application,
    internalFlowInventoryLogs,
    orders,
    tariffs,
    singleTariff,
    singleOrder,
    carrierWeightPricing,
    followUps
};
