import { shipment, shipmentOverview, relatedShipment, FollowUp } from '../../../models/entities/shipment/shipment';
import { ModelConfig } from '@rematch/core';
import { party } from '../../../models/entities/common_subentities/party';
import { party as bidParty } from '../../../models/entities/bid/party';
import { event } from '../../../models/entities/common_subentities/event';
import { document, documents } from '../../../models/entities/common_subentities/document';
import { shipmentPackage } from '../../../models/entities/shipment/shipmentPackage';
import { route } from '../../../models/entities/shipment/route';
import { discussion } from '../../../models/entities/common_subentities/discussion';
import { shipmentReference } from '../../../models/entities/shipment/shipmentReference';
import { localSDK as client } from '../../../sdk';
import moment from 'moment';
import { shipmentFollower } from '../../../models/entities/shipment/shipmentFollower';
import { addParty, editParty, removeParty, setParties } from '../../util/common_subentities/parties';
import { addEvent, editEvent, removeEvent, setEvents } from '../../util/common_subentities/events';
import { addDocument, editDocument, removeDocument, setDocuments } from '../../util/common_subentities/documents';
import { addDiscussion, editDiscussion, removeDiscussion, setDiscussions } from '../../util/common_subentities/discussions';
import { setHistory } from '../../util/common_subentities/history';
import { RootState } from '../../store/store';
import { uniqBy } from 'lodash';
import { history } from '../../../models/entities/common_subentities/history';
import { shipmentAlert } from '../../../models/entities/shipment/shipmentAlert';
import { addEntity, editEntity, removeEntity, setEntities } from '../../util/common_subentities/generic';
import { bid } from '../../../models/entities/bid/bid';
import { quotation, quotationView } from '../../../models/entities/bid/quotation';
import shipmentItem from '../../../models/entities/shipment/shipmentItem';
import { loadShipmentSvgIcons } from '../../../components/pages/singleShipment/ShipmentPdfDocument';
import { checkOrderConfirmationResponse, confirmOrderPayload } from '../../../models/entities/shipment/public/orderConfirmation';
import { shipmentContainer } from '../../../models/entities/shipment/shipmentContainer';
import { tariffQuotation } from '../../../models/entities/shipment/tariffQuotation';
import { billingLine } from '../../../models/entities/common_subentities/billingLine';
import { addBillingLine, editBillingLine, removeBillingLine, setBillingLines } from '../../util/common_subentities/accounting';
export interface MetaShipment {
    id: string;
    shipmentId: string;
    CargoZoneNumber: string;
}
export type shipmentsStateType = {
    shipments: Array<shipment>;
    metaShipments: Array<MetaShipment>;
    error?: string;
    errorFetchSingleShipment?: string;
};

const checkLastEvent = (events?: Array<event>): event | undefined => {
    const lastEvent: event | undefined = events?.reduce((max, event) => (moment(max.eventDate) > moment(event.eventDate) ? max : event));

    return lastEvent;
};

type createShipmentEventPayload = {
    shipmentNumber: string;
    event: event;
};

type createShipmentPayload = {
    shipment: shipment;
};

type updateShipmentPayload = {
    id: string;
    shipment: shipment;
};

type updateShipmentOverviewPayload = {
    id: string;
    shipmentOverview: shipmentOverview;
};
type shareShipmentPayload = {
    emails: string[];
    content: string;
    shipmentNumber: string;
    files: string[];
    shipmentPdfFile: File;
};
type inviteUsersToFollowPayload = {
    users: Array<string>;
    shipmentId: string;
    shipmentNumber: string;
    message: string;
};

type createMultipleShipmentItemsPayload = {
    shipmentId: string;
    items: Array<shipmentItem>;
};

export const shipments: ModelConfig<shipmentsStateType> = {
    state: {
        shipments: [],
        metaShipments: []
    },
    reducers: {
        setShipments(state: shipmentsStateType, shipments: Array<shipment>): shipmentsStateType {
            return { ...state, shipments };
        },
        setMetaShipments(state: shipmentsStateType, metaShipments: Array<MetaShipment>): shipmentsStateType {
            return { ...state, metaShipments };
        },
        bulkUpdateShipments(state: shipmentsStateType, mutatedShipments: shipment[]): shipmentsStateType {
            const shipments = state.shipments.map((shipment) => {
                if (mutatedShipments.find((s) => s.id === shipment.id)) {
                    return mutatedShipments.find((s) => s.id === shipment.id) || shipment;
                }
                return shipment;
            });
            return { ...state, shipments };
        },

        setFetchShipmentsError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        setSingleShipment(state: shipmentsStateType, newShipment: shipment): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === newShipment.id);
            if (shipmentIndex !== -1) {
                newShipments[shipmentIndex] = newShipment;
            } else {
                newShipments.push(newShipment);
            }
            return { ...state, shipments: newShipments };
        },
        setMultipleShipments(state: shipmentsStateType, shipments: shipment[]): shipmentsStateType {
            const newShipments = [...state.shipments];
            shipments.forEach((shipment) => {
                const shipmentIndex = newShipments.findIndex((s) => s.id === shipment.id);

                if (shipmentIndex !== -1) {
                    // newShipments[shipmentIndex] = shipment;
                } else {
                    newShipments.push(shipment);
                }
            });
            return { ...state, shipments: newShipments };
        },
        setShipmentPackages(state: shipmentsStateType, shipmentId: string, shipmentPackages: Array<shipmentPackage>): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                newShipments[shipmentIndex].packages = shipmentPackages;
            }
            return { ...state, shipments: newShipments };
        },
        setShipmentReferences(state: shipmentsStateType, shipmentId: string, shipmentReferences: Array<shipmentReference>): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                newShipments[shipmentIndex].references = shipmentReferences;
            }
            return { ...state, shipments: newShipments };
        },
        setShipmentRoutes(state: shipmentsStateType, shipmentId: string, shipmentRoutes: Array<route>): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                newShipments[shipmentIndex].routes = shipmentRoutes;
            }
            return { ...state, shipments: newShipments };
        },
        addShipment(state: shipmentsStateType, shipment: shipment): shipmentsStateType {
            let currentShipments = [...state.shipments];
            currentShipments.push(shipment);
            return { ...state, shipments: currentShipments };
        },
        removeShipment(state: shipmentsStateType, shipmentNumber: string): shipmentsStateType {
            const newShipments = [...state.shipments];
            return { ...state, shipments: newShipments.filter((item) => item.id !== shipmentNumber) };
        },
        editShipment(state: shipmentsStateType, id: string, shipment: shipment): shipmentsStateType {
            let currentShipments = [...state.shipments];
            const shipmentIndex = currentShipments.findIndex((currentShipment) => currentShipment.id === id);

            if (shipmentIndex !== -1) {
                currentShipments[shipmentIndex] = { ...JSON.parse(JSON.stringify(shipment)), id };
            }
            return { ...state, shipments: currentShipments };
        },
        editShipmentOverview(state: shipmentsStateType, id: string, shipmentOverview: shipmentOverview): shipmentsStateType {
            let currentShipments = [...state.shipments];
            const shipmentIndex = currentShipments.findIndex((currentShipment) => currentShipment.id === id);

            if (shipmentIndex !== -1) {
                currentShipments[shipmentIndex] = { ...currentShipments[shipmentIndex], ...JSON.parse(JSON.stringify(shipmentOverview)), id };
            }
            return { ...state, shipments: currentShipments };
        },
        dismissAlert(state: shipmentsStateType, shipmentId: string, alertId: number): shipmentsStateType {
            const currentShipments = [...state.shipments];
            const shipmentIndex = currentShipments.findIndex((item) => item.id === shipmentId);

            if (shipmentIndex !== -1) {
                const currentAlerts = currentShipments[shipmentIndex].alerts;

                if (currentAlerts) {
                    const alertIndex = currentAlerts?.findIndex((a) => a.id === alertId);
                    if (alertIndex !== -1) {
                        currentAlerts[alertIndex].isDismissed = true;
                    }
                }
            }

            return { ...state, shipments: currentShipments };
        },
        setShipmentAlerts(state: shipmentsStateType, shipmentId: string, shipmentAlerts: Array<shipmentAlert>): shipmentsStateType {
            const currentShipments = [...state.shipments];
            const shipmentIndex = currentShipments.findIndex((item) => item.id === shipmentId);
            if (shipmentIndex !== -1) {
                currentShipments[shipmentIndex].alerts = shipmentAlerts;
            }

            return { ...state, shipments: currentShipments };
        },
        setShipmentEvents(state: shipmentsStateType, shipmentId: string, shipmentEvents: Array<event>): shipmentsStateType {
            return { ...state, shipments: setEvents(state, shipmentId, 'shipments', shipmentEvents) };
        },
        addShipmentEvent(state: shipmentsStateType, shipmentNumber: string, event: event): shipmentsStateType {
            return { ...state, shipments: addEvent(state, shipmentNumber, 'shipments', event, checkLastEvent) };
        },
        editShipmentEvent(state: shipmentsStateType, shipmentNumber: string, event: event): shipmentsStateType {
            return { ...state, shipments: editEvent(state, shipmentNumber, 'shipments', event, checkLastEvent) };
        },
        removeShipmentEvent(state: shipmentsStateType, shipmentNumber: string, eventId: number): shipmentsStateType {
            return { ...state, shipments: removeEvent(state, shipmentNumber, 'shipments', eventId, checkLastEvent) };
        },
        setShipmentBillingLines(state: shipmentsStateType, shipmentId: string, shipmentBillingLines: Array<billingLine>): shipmentsStateType {
            return { ...state, shipments: setBillingLines(state, shipmentId, 'shipments', shipmentBillingLines) };
        },
        addShipmentBillingLine(state: shipmentsStateType, shipmentNumber: string, billingLine: billingLine): shipmentsStateType {
            return { ...state, shipments: addBillingLine(state, shipmentNumber, 'shipments', billingLine) };
        },
        editShipmentBillingLine(state: shipmentsStateType, shipmentNumber: string, billingLine: billingLine): shipmentsStateType {
            return { ...state, shipments: editBillingLine(state, shipmentNumber, 'shipments', billingLine) };
        },
        removeShipmentBillingLine(state: shipmentsStateType, shipmentNumber: string, billingLineId: number): shipmentsStateType {
            return { ...state, shipments: removeBillingLine(state, shipmentNumber, 'shipments', billingLineId) };
        },
        setShipmentDocuments(state: shipmentsStateType, shipmentId: string, shipmentDocuments: documents): shipmentsStateType {
            return { ...state, shipments: setDocuments(state, shipmentId, 'shipments', shipmentDocuments) };
        },
        addShipmentDocument(state: shipmentsStateType, shipmentNumber: string, document: document): shipmentsStateType {
            return { ...state, shipments: addDocument(state, shipmentNumber, 'shipments', document) };
        },
        editShipmentDocument(state: shipmentsStateType, shipmentNumber: string, document: document): shipmentsStateType {
            return { ...state, shipments: editDocument(state, shipmentNumber, 'shipments', document) };
        },
        removeShipmentDocument(state: shipmentsStateType, shipmentNumber: string, documentId: number): shipmentsStateType {
            return { ...state, shipments: removeDocument(state, shipmentNumber, 'shipments', documentId) };
        },
        setShipmentParties(state: shipmentsStateType, shipmentId: string, shipmentParties: Array<party>): shipmentsStateType {
            return { ...state, shipments: setParties(state, shipmentId, 'shipments', shipmentParties) };
        },
        addShipmentParty(state: shipmentsStateType, shipmentNumber: string, party: party): shipmentsStateType {
            return { ...state, shipments: addParty(state, shipmentNumber, 'shipments', party) };
        },
        editShipmentParty(state: shipmentsStateType, shipmentNumber: string, party: party): shipmentsStateType {
            return { ...state, shipments: editParty(state, shipmentNumber, 'shipments', party) };
        },
        removeShipmentParty(state: shipmentsStateType, shipmentNumber: string, partyId: number): shipmentsStateType {
            return { ...state, shipments: removeParty(state, shipmentNumber, 'shipments', partyId) };
        },
        addShipmentReference(state: shipmentsStateType, shipmentNumber: string, reference: shipmentReference): shipmentsStateType {
            return { ...state, shipments: addEntity<shipmentReference>(state, shipmentNumber, 'shipments', 'references', reference) };
        },
        editShipmentReference(state: shipmentsStateType, shipmentNumber: string, reference: shipmentReference): shipmentsStateType {
            return { ...state, shipments: editEntity<shipmentReference>(state, shipmentNumber, 'shipments', 'references', reference) };
        },
        removeShipmentReference(state: shipmentsStateType, shipmentNumber: string, referenceId: number): shipmentsStateType {
            return { ...state, shipments: removeEntity<document>(state, shipmentNumber, 'shipments', 'references', referenceId) };
        },
        setShipmentDiscussions(state: shipmentsStateType, shipmentId: string, shipmentDiscussions: Array<discussion>): shipmentsStateType {
            return { ...state, shipments: setDiscussions(state, shipmentId, 'shipments', shipmentDiscussions) };
        },
        addShipmentDiscussion(state: shipmentsStateType, shipmentNumber: string, discussion: discussion): shipmentsStateType {
            return { ...state, shipments: addDiscussion(state, shipmentNumber, 'shipments', discussion) };
        },
        editShipmentDiscussion(state: shipmentsStateType, shipmentNumber: string, discussion: discussion): shipmentsStateType {
            return { ...state, shipments: editDiscussion(state, shipmentNumber, 'shipments', discussion) };
        },
        removeShipmentDiscussion(state: shipmentsStateType, shipmentNumber: string, discussionId: string): shipmentsStateType {
            return { ...state, shipments: removeDiscussion(state, shipmentNumber, 'shipments', discussionId) };
        },
        setShipmentHistory(state: shipmentsStateType, shipmentId: string, shipmentHistory: Array<history>): shipmentsStateType {
            return { ...state, shipments: setHistory(state, shipmentId, 'shipments', shipmentHistory) };
        },
        removeShipmentRequiredDoc(state: shipmentsStateType, shipmentNumber: string, docType: string): shipmentsStateType {
            const newArr = [...state.shipments];
            const index = newArr.findIndex((item) => item.id === shipmentNumber);
            if (index !== -1 && newArr[index].documents) {
                const oldEntities = newArr[index].documents?.requiredDocs?.filter((item: string) => item !== docType);
                newArr[index].documents = { ...newArr[index].documents, requiredDocs: oldEntities };
            }
            return { ...state, shipments: newArr };
        },
        setShipmentState(state: shipmentsStateType, shipmentId: string, shipmentState: string): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                newShipments[shipmentIndex].state = shipmentState;
            }
            return { ...state, shipments: newShipments };
        },
        setShipmentBid(state: shipmentsStateType, shipmentId: string, bid: bid): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                newShipments[shipmentIndex].bid = { ...newShipments[shipmentIndex].bid, ...bid };
            }
            return { ...state, shipments: newShipments };
        },
        setShipmentBidParties(state: shipmentsStateType, shipmentId: string, parties: Array<bidParty>): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                const newShipment = { ...newShipments[shipmentIndex] };
                if (newShipment.bid) {
                    const newBid = { ...newShipment.bid };
                    newBid.parties = [...parties, ...(newBid.parties || [])];
                    newShipments[shipmentIndex] = { ...newShipment, bid: newBid };
                }
            }
            return { ...state, shipments: newShipments };
        },
        setShipmentTariffQuotations(state: shipmentsStateType, shipmentId: string, tariffQuotations: Array<tariffQuotation>): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                const newShipment = { ...newShipments[shipmentIndex] };

                const newTariffQuotations = [...(newShipments[shipmentIndex].tariffQuotations || []), ...tariffQuotations];
                newShipments[shipmentIndex] = { ...newShipment, tariffQuotations: newTariffQuotations };
            }

            return { ...state, shipments: newShipments };
        },
        setShipmentBidQuotations(state: shipmentsStateType, shipmentId: string, quotations: Array<quotationView>): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                const newShipment = { ...newShipments[shipmentIndex] };
                if (newShipment.bid) {
                    const newBid = { ...newShipment.bid };
                    newBid.quotations = [...quotations, ...(newBid.quotations || [])];
                    newShipments[shipmentIndex] = { ...newShipment, bid: newBid };
                }
            }
            return { ...state, shipments: newShipments };
        },
        addShipmentFollower(state: shipmentsStateType, shipmentNumber: string, shipmentFollower: shipmentFollower): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentNumber);
            if (shipmentIndex !== -1) {
                let followers = [shipmentFollower];
                const oldFollowers = newShipments[shipmentIndex].followers;
                if (oldFollowers && oldFollowers.length > 0) followers = [...oldFollowers, ...followers];
                newShipments[shipmentIndex].followers = followers;
            }
            return { ...state, shipments: newShipments };
        },
        removeShipmentFollower(state: shipmentsStateType, shipmentNumber: string, userId: string): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentNumber);
            if (shipmentIndex !== -1) {
                const oldFollowers = newShipments[shipmentIndex].followers?.filter((item) => item.userId !== userId);
                newShipments[shipmentIndex].followers = oldFollowers;
            }
            return { ...state, shipments: newShipments };
        },
        setShipmentItem(state: shipmentsStateType, item: shipmentItem): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === item.shipmentId);
            if (shipmentIndex !== -1) {
                const currentShipment = { ...newShipments[shipmentIndex] };
                const currentShipmentItems = [...(currentShipment.items || [])];
                const shipmentItemIndex = currentShipmentItems.findIndex((shipmentItem) => shipmentItem.id === item.id);
                if (shipmentItemIndex !== -1) {
                    currentShipmentItems[shipmentItemIndex] = item;
                    currentShipment.items = currentShipmentItems;
                    newShipments[shipmentIndex] = currentShipment;
                }
            }
            return { ...state, shipments: newShipments };
        },
        addMultipleShipmentItems(state: shipmentsStateType, shipmentId: string, items: Array<shipmentItem>): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((p) => p.id === shipmentId);
            if (shipmentIndex !== -1) {
                const currentShipment = { ...newShipments[shipmentIndex] };
                const newItemsIds = items.map((i) => i.id);
                const currentShipmentItems = [...(currentShipment.items || [])].filter((item) => !newItemsIds.includes(item.id));
                const newShipmentItems = [...currentShipmentItems, ...items];
                currentShipment.items = newShipmentItems;
                newShipments[shipmentIndex] = currentShipment;
            }
            return { ...state, shipments: [...newShipments] };
        },
        removeShipmentItem(state: shipmentsStateType, shipmentId: string, itemId: string) {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((p) => p.id === shipmentId);

            if (shipmentIndex !== -1) {
                const currentShipment = { ...newShipments[shipmentIndex] };
                const shipmentItemIndex = currentShipment?.items?.findIndex((item) => item.id === itemId);

                if (shipmentItemIndex != null && shipmentItemIndex !== -1) currentShipment.items?.splice(shipmentItemIndex, 1);
                newShipments[shipmentIndex] = currentShipment;
            }
            return { ...state, shipments: [...newShipments] };
        },
        setShipmentIsActive(state: shipmentsStateType, shipmentId: string, isActive: boolean): shipmentsStateType {
            const newShipments = [...state.shipments];
            const shipmentIndex = newShipments.findIndex((shipment) => shipment.id === shipmentId);
            if (shipmentIndex !== -1) {
                newShipments[shipmentIndex].isActive = isActive;
            }
            return { ...state, shipments: newShipments };
        },
        removeShipmentContainer(state: shipmentsStateType, shipmentId: string, containerId: string): shipmentsStateType {
            return { ...state, shipments: removeEntity<shipmentContainer>(state, shipmentId, 'shipments', 'containers', containerId) };
        },
        removeShipmentPackage(state: shipmentsStateType, shipmentId: string, packageId: string): shipmentsStateType {
            return { ...state, shipments: removeEntity<shipmentPackage>(state, shipmentId, 'shipments', 'packages', packageId) };
        },
        setRelatedShipments(state: shipmentsStateType, shipmentId: string, relatedShipments: relatedShipment[]): shipmentsStateType {
            return { ...state, shipments: setEntities<relatedShipment>(state, shipmentId, 'shipments', 'relatedShipments', relatedShipments) };
        },
        setFetchSingleShipmentError(state: shipmentsStateType, error: string) {
            return { ...state, errorFetchSingleShipment: error };
        },
        setFetchShipmentPartiesError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        setFetchShipmentEventsError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        setFetchShipmentBillingLinesError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        setFetchShipmentDocumentsError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        setFetchShipmentPackagesError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        setFetchShipmentReferencesError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        setFetchShipmentRoutesError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        setError(state: shipmentsStateType, error: string): shipmentsStateType {
            return { ...state, error };
        },
        addShipmentFollowUp(state: shipmentsStateType, { shipmentId, followUp }: { shipmentId: string; followUp: FollowUp }): shipmentsStateType {
            const newShipments = state.shipments.map((shipment) => {
                if (shipment.id === shipmentId) {
                    const updatedFollowUps = shipment.followUps ? [...shipment.followUps, followUp] : [followUp];
                    return { ...shipment, followUps: updatedFollowUps };
                }
                return shipment;
            });
            return { ...state, shipments: newShipments };
        },
        updateShipmentFollowUp(state: shipmentsStateType, { shipmentId, followUp }: { shipmentId: string; followUp: FollowUp }): shipmentsStateType {
            const newShipments = state.shipments.map((shipment) => {
                if (shipment.id === shipmentId && shipment.followUps) {
                    const updatedFollowUps = shipment.followUps.map((fu) => (fu.id === followUp.id ? followUp : fu));
                    return { ...shipment, followUps: updatedFollowUps };
                }
                return shipment;
            });
            return { ...state, shipments: newShipments };
        },
        removeShipmentFollowUp(
            state: shipmentsStateType,
            { shipmentId, followUpId }: { shipmentId: string; followUpId: number }
        ): shipmentsStateType {
            const newShipments = state.shipments.map((shipment) => {
                if (shipment.id === shipmentId && shipment.followUps) {
                    const updatedFollowUps = shipment.followUps.filter((fu) => fu.id !== followUpId);
                    return { ...shipment, followUps: updatedFollowUps };
                }
                return shipment;
            });
            return { ...state, shipments: newShipments };
        }
    },
    effects: (dispatch: any) => ({
        async fetchShipments() {
            const shipments = await client.shipments().fetchShipments();
            dispatch.shipments.setShipments(shipments);
            dispatch.allShipmentsPage.setCurrentPage(0);
        },
        async fetchMetaShipments() {
            const shipments = await client.shipments().fetchMetaShipments();
            dispatch.shipments.setMetaShipments(shipments);
        },
        async fetchShipmentsDashboard() {
            const shipments = await client.shipments().fetchShipmentsDashboard();
            dispatch.shipments.setShipments(shipments);
        },
        async fetchShipmentById(shipmentId: string) {
            const shipmentData = await client.shipments().fetchById(shipmentId);
            dispatch.shipments.setSingleShipment(shipmentData);

            const icons = await loadShipmentSvgIcons(shipmentData);
            dispatch.singleShipment.setShipmentPdfSvgIcons(icons);

            return shipmentData;
        },
        async fetchRelatedShipmentsById(shipmentId: string) {
            const related = await client.shipments().fetchRelatedShipmentsById(shipmentId);
            dispatch.shipments.setRelatedShipments(shipmentId, related);
        },
        async reStateShipmentById(shipmentId: string) {
            const shipmentData = await client.shipments().reStateShipmentById(shipmentId);
            dispatch.shipments.setSingleShipment(shipmentData);
        },

        async fetchShipmentsByIds(shipmentsIds: string[]) {
            const shipmentsData = await client.shipments().fetchByIds(shipmentsIds);
            dispatch.shipments.setMultipleShipments(shipmentsData);
        },
        async fetchShipmentsByCZNumber(czNumber: string) {
            const shipmentsData = await client.shipments().fetchByCZNumber(czNumber);
            return shipmentsData;
        },
        async uploadShipmentsByExcel({ file, companyId, companyName }: { file: File; companyId?: string; companyName?: string }) {
            await client.shipments().uploadShipmentsByExcel(file, companyId, companyName);
            dispatch.shipments.fetchShipments();
            return true;
        },
        async fetchShipmentPartiesById(shipmentId: string) {
            const shipmentPartiesData = await client.shipments().parties().fetchShipmentParties(shipmentId);
            dispatch.shipments.setShipmentParties(shipmentId, shipmentPartiesData);
        },
        async fetchShipmentAlertsById(shipmentId: string) {
            const shipmentAlertsData = await client.shipments().alerts().fetchShipmentAlerts(shipmentId);
            dispatch.shipments.setShipmentAlerts(shipmentId, shipmentAlertsData);
        },
        async dismissShipmentAlert({ shipmentId, alert }: { shipmentId: string; alert: shipmentAlert }) {
            dispatch.shipments.dismissAlert(shipmentId, alert.id);
            await client.shipments().alerts().dismissShipmentAlert(shipmentId, alert);
        },
        async fetchShipmentEventsById(shipmentId: string) {
            const shipmentEventsData = await client.shipments().events().fetchShipmentEvents(shipmentId);
            dispatch.shipments.setShipmentEvents(shipmentId, shipmentEventsData);
        },
        async fetchShipmentBillingLinesById(shipmentId: string) {
            const shipmentBillingLinesData = await client.shipments().billingLines().fetchShipmentBillingLines(shipmentId);
            dispatch.shipments.setShipmentBillingLines(shipmentId, shipmentBillingLinesData);
        },
        async fetchShipmentDocumentsById(shipmentId: string) {
            const shipmentDocumentsData = await client.shipments().documents().fetchShipmentDocuments(shipmentId);
            dispatch.shipments.setShipmentDocuments(shipmentId, shipmentDocumentsData);
        },
        async fetchShipmentPackagesById(shipmentId: string) {
            const shipmentPackagesData = await client.shipments().fetchShipmentPackages(shipmentId);
            dispatch.shipments.setShipmentPackages(shipmentId, shipmentPackagesData);
        },
        async fetchShipmentReferencesById(shipmentId: string) {
            const shipmentReferencesData = await client.shipments().fetchShipmentReferences(shipmentId);
            dispatch.shipments.setShipmentReferences(shipmentId, shipmentReferencesData);
        },
        async fetchShipmentRoutesById(shipmentId: string) {
            const shipmentRoutesData = await client.shipments().fetchShipmentRoutes(shipmentId);
            dispatch.shipments.setShipmentRoutes(shipmentId, shipmentRoutesData);
        },
        async fetchShipmentDiscussionsById(shipmentId: string) {
            const shipmentDiscussionsData = await client.shipments().discussions().fetchShipmentDiscussions(shipmentId);
            dispatch.shipments.setShipmentDiscussions(shipmentId, shipmentDiscussionsData);
        },
        async fetchShipmentHistoryById(shipmentId: string) {
            const shipmentHistoryData = await client.shipments().history().fetchShipmentHistoryById(shipmentId);
            dispatch.shipments.setShipmentHistory(shipmentId, shipmentHistoryData);
        },
        async createShipment({ shipment }: createShipmentPayload) {
            const createdShipmentId = await client.shipments().createShipment(shipment);
            return createdShipmentId;
        },
        async updateShipment({ id, shipment }: updateShipmentPayload) {
            const updatedShipment = await client.shipments().updateShipment(id, shipment);
            dispatch.shipments.editShipment(id, updatedShipment);
            return true;
        },
        async fetchSendToSapGUID(guid: string) {
            const SapGUID = await client.shipments().fetchSendToSapGUID(guid);
            return true;
        },
        async updateShipmentOverview({ id, shipmentOverview }: updateShipmentOverviewPayload) {
            const shipmentData = await client.shipments().updateShipmentOverview(id, shipmentOverview);
            // dispatch.shipments.editShipmentOverview(id, shipmentOverview);
            dispatch.shipments.setSingleShipment(shipmentData);
            dispatch.shipments.fetchShipmentHistoryById(id);
            return true;
        },
        async updateShipmentTransportationPaid({ shipmentId, transportationPaid }: { shipmentId: string; transportationPaid: boolean }) {
            dispatch.shipments.editShipmentOverview(shipmentId, { transportationPaid });
            await client.shipments().updateTransportationPaid(shipmentId, transportationPaid);
            return true;
        },
        async updateShipmentForwarder({ id, forwarderId }: { id: string; forwarderId: string | null }) {
            const shipmentData = await client.shipments().updateShipmentForwarder(id, forwarderId);
            dispatch.shipments.setSingleShipment(shipmentData);
            return true;
        },
        async shareShipment({ emails, content, shipmentNumber, files, shipmentPdfFile }: shareShipmentPayload) {
            await client.shipments().shareShipment(emails, content, shipmentNumber, files, shipmentPdfFile);
            return true;
        },
        async sendShipmentPaidEmail({
            contactsEmails,
            shipmentCzNumber,
            shipmentPdfFile
        }: {
            contactsEmails: string[];
            shipmentCzNumber: string;
            shipmentPdfFile: File;
        }) {
            await client.businessPartners().sendShipmentPaidEmail(contactsEmails, shipmentCzNumber, shipmentPdfFile);
            return true;
        },
        async inviteUsersToFollow({ users, shipmentId, shipmentNumber, message }: inviteUsersToFollowPayload) {
            await client.shipments().inviteUsersToFollow(users, shipmentId, shipmentNumber, message);
            return true;
        },
        async createShipmentEvent({ shipmentNumber, event }: createShipmentEventPayload) {
            const createdEvent = await client.shipments().events().createShipmentEvent(shipmentNumber, event);
            if (createdEvent.newShipmentState) {
                dispatch.shipments.setShipmentState(shipmentNumber, createdEvent.newShipmentState);
            }
            dispatch.shipments.addShipmentEvent(shipmentNumber, createdEvent);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async updateShipmentEvent({ shipmentNumber, event }: createShipmentEventPayload) {
            await client.shipments().events().updateShipmentEvent(shipmentNumber, event);
            dispatch.shipments.editShipmentEvent(shipmentNumber, event);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async deleteShipmentEvent({ shipmentNumber, eventId, userId }: { shipmentNumber: string; eventId: number; userId: string }) {
            await client.shipments().events().deleteShipmentEvent(shipmentNumber, eventId, userId);
            dispatch.shipments.removeShipmentEvent(shipmentNumber, eventId);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async createShipmentBillingLine({ shipmentNumber, billingLine }: { shipmentNumber: string; billingLine: billingLine }) {
            const createdBillingLine = await client.shipments().billingLines().createShipmentBillingLine(shipmentNumber, billingLine);
            dispatch.shipments.addShipmentBillingLine(shipmentNumber, createdBillingLine);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async updateShipmentBillingLine({ shipmentNumber, billingLine }: { shipmentNumber: string; billingLine: billingLine }) {
            await client.shipments().billingLines().updateShipmentBillingLine(shipmentNumber, billingLine);
            dispatch.shipments.editShipmentBillingLine(shipmentNumber, billingLine);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async deleteShipmentBillingLine({ shipmentNumber, billingLineId }: { shipmentNumber: string; billingLineId: number }) {
            await client.shipments().billingLines().deleteShipmentBillingLine(shipmentNumber, billingLineId);
            dispatch.shipments.removeShipmentBillingLine(shipmentNumber, billingLineId);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async createMultipleShipmentDocument({ shipmentNumber, documents }: { shipmentNumber: string; documents: Array<document> }) {
            dispatch.loadingNotify.setLoadingState({ visible: true, text: 'Uploading files...' });
            const createdDocuments = await client.shipments().documents().createMultipleShipmentDocument(shipmentNumber, documents);
            createdDocuments.forEach((document: document) => {
                dispatch.shipments.addShipmentDocument(shipmentNumber, document);
            });
            dispatch.loadingNotify.setLoadingState({ visible: false });
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async createShipmentDocument({ shipmentNumber, document }: { shipmentNumber: string; document: document }) {
            const createdDocument = await client.shipments().documents().createShipmentDocument(shipmentNumber, document);
            dispatch.shipments.addShipmentDocument(shipmentNumber, createdDocument);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async updateShipmentDocument({ shipmentNumber, document }: { shipmentNumber: string; document: document }) {
            await client.shipments().documents().updateShipmentDocument(shipmentNumber, document);
            dispatch.shipments.editShipmentDocument(shipmentNumber, document);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async deleteShipmentDocument({ shipmentNumber, documentId, userId }: { shipmentNumber: string; documentId: number; userId: string }) {
            await client.shipments().documents().deleteShipmentDocument(shipmentNumber, documentId, userId);
            dispatch.shipments.removeShipmentDocument(shipmentNumber, documentId);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async createShipmentParty({ shipmentNumber, party }: { shipmentNumber: string; party: party }) {
            const createdParty = await client.shipments().parties().createShipmentParty(shipmentNumber, party);
            dispatch.shipments.addShipmentParty(shipmentNumber, createdParty);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async updateShipmentParty({ shipmentNumber, party }: { shipmentNumber: string; party: party }) {
            await client.shipments().parties().updateShipmentParty(shipmentNumber, party);
            dispatch.shipments.editShipmentParty(shipmentNumber, party);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async deleteShipmentParty({ shipmentNumber, partyId }: { shipmentNumber: string; partyId: number }) {
            await client.shipments().parties().deleteShipmentParty(shipmentNumber, partyId);
            dispatch.shipments.removeShipmentParty(shipmentNumber, partyId);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async createShipmentReference({ shipmentNumber, reference }: { shipmentNumber: string; reference: shipmentReference }) {
            const createdReference = await client.shipments().references().createShipmentReference(shipmentNumber, reference);
            dispatch.shipments.addShipmentReference(shipmentNumber, createdReference);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async updateShipmentReference({ shipmentNumber, reference }: { shipmentNumber: string; reference: shipmentReference }) {
            await client.shipments().references().updateShipmentReference(shipmentNumber, reference);
            dispatch.shipments.editShipmentReference(shipmentNumber, reference);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async deleteShipmentReference({ shipmentNumber, referenceId }: { shipmentNumber: string; referenceId: number }) {
            await client.shipments().references().deleteShipmentReference(shipmentNumber, referenceId);
            dispatch.shipments.removeShipmentReference(shipmentNumber, referenceId);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async createShipmentItems({ shipmentId, items }: createMultipleShipmentItemsPayload) {
            const createdItems = await client.shipments().items().createMultipleShipmentItems(shipmentId, items);
            dispatch.shipments.addMultipleShipmentItems(shipmentId, createdItems);
            dispatch.shipments.fetchShipmentHistoryById(shipmentId);
        },
        async updateShipmentItem(item: shipmentItem) {
            dispatch.shipments.setShipmentItem(item);
            await client.shipments().items().updateShipmentItem(item);
            dispatch.shipments.fetchShipmentHistoryById(item.shipmentId);
        },
        async deleteShipmentItem({ shipmentId, itemId }: { shipmentId: string; itemId: string }) {
            dispatch.shipments.removeShipmentItem(shipmentId, itemId);
            await client.shipments().items().deleteShipmentItem(shipmentId, itemId);
        },
        async createShipmentDiscussion({
            shipmentId,
            shipmentNumber,
            discussion
        }: {
            shipmentId: string;
            discussion: discussion;
            shipmentNumber?: string;
        }) {
            const createdDiscussion = await client.shipments().discussions().createShipmentDiscussion(shipmentId, discussion, shipmentNumber);
            dispatch.shipments.addShipmentDiscussion(shipmentId, createdDiscussion);
            dispatch.shipments.fetchShipmentHistoryById(shipmentId);
        },
        async updateShipmentDiscussion({
            shipmentId,
            shipmentNumber,
            discussion
        }: {
            shipmentId: string;
            discussion: discussion;
            shipmentNumber?: string;
        }) {
            dispatch.shipments.editShipmentDiscussion(shipmentId, discussion);
            await client.shipments().discussions().updateShipmentDiscussion(shipmentId, discussion, shipmentNumber);
            dispatch.shipments.fetchShipmentHistoryById(shipmentId);
        },
        async deleteShipmentDiscussion({ shipmentNumber, discussionId }: { shipmentNumber: string; discussionId: number }) {
            await client.shipments().discussions().deleteShipmentDiscussion(shipmentNumber, discussionId);
            dispatch.shipments.removeShipmentDiscussion(shipmentNumber, discussionId);
            dispatch.shipments.fetchShipmentHistoryById(shipmentNumber);
        },
        async updateShipmentState({ shipmentId, shipmentState, forwarderId }: { shipmentId: string; shipmentState: string; forwarderId?: string }) {
            const shipment = await client.shipments().updateShipmentState(shipmentId, shipmentState, forwarderId);
            dispatch.shipments.editShipment(shipmentId, shipment);
            dispatch.shipments.fetchShipmentHistoryById(shipmentId);
        },
        async updateShipmentAccountingState({ shipmentId, shipmentAccountingState }: { shipmentId: string; shipmentAccountingState: string }) {
            const shipment = await client.shipments().updateShipmentAccountingState(shipmentId, shipmentAccountingState);
            dispatch.shipments.editShipment(shipmentId, shipment);
            dispatch.shipments.fetchShipmentHistoryById(shipmentId);
        },
        async dismissRequiredDoc({ shipmentId, docType }: { shipmentId: string; docType: string }) {
            await client.shipments().dismissRequiredDoc(shipmentId, docType);
            dispatch.shipments.removeShipmentRequiredDoc(shipmentId, docType);
        },
        async updateShipmentIsActive({ shipmentId, isActive }: { shipmentId: string; isActive: boolean }) {
            await client.shipments().updateShipmentIsActive(shipmentId, isActive);
            dispatch.shipments.setShipmentIsActive(shipmentId, isActive);
        },

        async checkOrderConfirmed(token: string): Promise<checkOrderConfirmationResponse> {
            const response = await client.shipments().checkOrderConfirmed(token);
            return response;
        },
        async confirmOrder(payload: confirmOrderPayload): Promise<void> {
            await client.shipments().confirmOrder(payload);
        },
        async deleteShipmentContainer({ shipmentId, containerId }: { shipmentId: string; containerId: string }): Promise<void> {
            await client.shipments().containers().deleteShipmentContainer(shipmentId, containerId);
            dispatch.shipments.removeShipmentContainer(shipmentId, containerId);
        },
        async deleteShipmentPackage({ shipmentId, packageId }: { shipmentId: string; packageId: string }): Promise<void> {
            await client.shipments().packages().deleteShipmentPackage(shipmentId, packageId);
            dispatch.shipments.removeShipmentPackage(shipmentId, packageId);
        },
        async addNewFollowUp({ shipmentId, followUp }: { shipmentId: string; followUp: FollowUp }): Promise<void> {
            const newFollowUp = await client.shipments().followUp().addNewFollowUp(shipmentId, followUp);
            dispatch.shipments.addShipmentFollowUp({ shipmentId, followUp: newFollowUp });
        },
        async updateFollowUpCompletionStatus({
            shipmentId,
            followUpId,
            isCompleted
        }: {
            shipmentId: string;
            followUpId: number;
            isCompleted: boolean;
        }): Promise<void> {
            const updatedFollowUp = await client.shipments().followUp().updateFollowUpCompletionStatus(shipmentId, followUpId, isCompleted);
            dispatch.shipments.updateShipmentFollowUp({ shipmentId, followUp: updatedFollowUp });
        },
        async deleteFollowUp({ shipmentId, followUpId }: { shipmentId: string; followUpId: number }): Promise<void> {
            await client.shipments().followUp().deleteFollowUp(shipmentId, followUpId);
            dispatch.shipments.removeShipmentFollowUp({ shipmentId, followUpId });
        }
    })
};
