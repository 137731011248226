import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import { settings as settingsModel, shipment } from '../../../models/entities/shipment/shipment';
import moment from 'moment';
import { countBy, groupBy, map, meanBy, sumBy, mean } from 'lodash';
import { summaryData } from '../../../models/entities/dashboard/summaryData';
import { pieChartData } from '../../../models/entities/dashboard/pieChartData';
import { eShipmentTypes } from '../../../models/entities/shipment/shipmentTypes';
import { byDate } from '../../../utils/sortArray';
import { shipmentAlert } from '../../../models/entities/shipment/shipmentAlert';
import mockData from '../../../sideEffects/shipments/settings/mockData';
import { userSelectors } from '../user/selectors';
import carriers from '../../../static/data/carriers.json';
import { userInfo } from '../../../models/entities/user';

const shipments = (state: RootState) => state.shipments.shipments;
const fetchShipmentsError = (state: RootState) => state.error.effects.shipments.fetchShipments;
const settings = (state: RootState) => state.user.userInfo.shipmentSettings;
const followingShipments = (state: RootState) => userSelectors.followingShipments(state);
const currentLanguage = (state: RootState) => state.localization.currentLanguage;

const shipmentsData = createSelector(shipments, (shipments: shipment[]) => {
    return shipments.map((shipment) => {
        const carrier = carriers.find((item) => item.carrierCode === shipment.carrierCode);
        return { ...shipment, carrierCode: carrier?.carrierName || shipment.carrierCode } as shipment;
    });
});
const ActiveShipments = createSelector(shipmentsData, (shipments) => {
    return shipments.filter((s) => s.isActive === true);
});
const settingsByLanguage = createSelector(settings, currentLanguage, (settings, currentLanguage) => (shipment?: shipment) => {
    const mockDataIndex = Object.keys(mockData).findIndex((item) => item === currentLanguage.symbol);
    const settingsObj: settingsModel = Object.values(mockData)[mockDataIndex !== -1 ? mockDataIndex : 0] as settingsModel;
    if (settings && shipment) {
        settings
            .filter((s) => {
                const flag =
                    s.shipmentType === shipment.typeCode && s.shipmentSubType === shipment.subTypeCode && s.language === currentLanguage.symbol;
                return flag;
            })
            .forEach((s) => {
                settingsObj[s.key as keyof settingsModel] = s.value;
            });
    }
    return settingsObj;
});

const shipmentStateOptions = createSelector(settingsByLanguage, (settingsByLanguage) => {
    const settings = settingsByLanguage();

    return (
        settings?.state
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            .map((item) => ({
                text: item.name,
                value: item.code,
                color: item.color
            })) || []
    );
});

const getShipmentById = (state: RootState, id: string): shipment | undefined => {
    return state.shipments.shipments?.find((shipment: shipment) => shipment.id === id);
};
const getShipmentIsActiveById = createSelector(shipmentsData, (shipmentsData: Array<shipment>) => (id: string): boolean => {
    return !!shipmentsData?.find((shipment: shipment) => shipment.id === id)?.isActive;
});
const getAlertsByShipmentId = (state: RootState, id: string): Array<shipmentAlert> | undefined => {
    return state.shipments.shipments?.find((shipment: shipment) => shipment.id === id)?.alerts;
};

const upcomingArrivalsShipments = createSelector(shipmentsData, (shipmentsData: Array<shipment>) => {
    return shipmentsData
        .filter((shipment: shipment) => moment(shipment.eta).isBetween(moment().subtract(5, 'days'), moment().add(10, 'days')))
        .sort(byDate(false, (shipment: shipment) => (shipment.eta ? shipment.eta : new Date())));
});

const readySoonShipments = createSelector(shipmentsData, (shipmentsData: Array<shipment>) => {
    return shipmentsData
        .filter((shipment: shipment) => moment(shipment.goodsReady).isBetween(moment().subtract(5, 'days'), moment().add(10, 'days')))
        .sort(byDate(false, (shipment: shipment) => (shipment.goodsReady ? shipment.goodsReady : new Date())));
});

const orderShipments = createSelector(shipmentsData, (shipmentsData: Array<shipment>) => {
    return shipmentsData
        .filter((shipment: shipment) => shipment.state === 'ORDER')
        .sort(byDate(false, (shipment: shipment) => (shipment.updatedAt ? shipment.updatedAt : new Date())));
});
const getSummaryData = createSelector(
    ActiveShipments,
    followingShipments,
    userSelectors.userInfo,
    (allShipments: Array<shipment>, followingShipments: Array<string>, userInfo: userInfo): summaryData => {
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');
        const isForwarder = userInfo.companyType === 'FORWARDER';

        const shipmentsData = allShipments.filter((shipment) => shipment.state !== 'DELIVERED');
        const activeShipments = shipmentsData.length;

        const deliveredShipments = shipmentsData.filter(
            (shipment) => shipment.state === 'DELIVERED' && moment(shipment.updatedAt).isBetween(startOfMonth, endOfMonth)
        ).length;

        const currentMonthArrivals = countBy(shipmentsData, ({ eta }) => moment(eta).isBetween(startOfMonth, endOfMonth)).true;
        const alertsCountByStatus = (status: number) => countBy(shipmentsData, ({ alertStatus }) => alertStatus === status).true;
        const getDraftDuration = (shipment: shipment) => {
            const now = new Date();
            const draftDate = new Date(shipment.updatedAt!);
            const diffInHours = (now.getTime() - draftDate.getTime()) / (1000 * 60 * 60);
            return diffInHours;
        };

        const criticalAlerts = () =>
            countBy(shipmentsData, (shipment) => shipment.alertStatus === 1 || (shipment.state == 'DRAFT' && getDraftDuration(shipment) > 96)).true;

        const warningAlerts = () =>
            countBy(shipmentsData, (shipment) => shipment.alertStatus === 2 || (shipment.state == 'DRAFT' && getDraftDuration(shipment) > 72)).true;

        //init possible undefined variables
        let shipmentsByVendor: pieChartData | undefined = undefined;
        let shipmentsByCargoOwner: pieChartData | undefined = undefined;
        let transportationCostAvg: number | undefined = undefined;
        let openInboundSpend: Array<{ currency: string; price: number }> | undefined = undefined;
        let shipmentProfitAvg: number | undefined = undefined;

        const shipmentsWithTransCost = shipmentsData.filter(
            (shipment) =>
                shipment.transportationPriceCurrency === shipment.goodsValueCurrency &&
                !!Number(shipment.transportationPrice) &&
                !!Number(shipment.goodsValue)
        );

        const shipmentsByForwarderData = map(
            groupBy(
                shipmentsData.filter((shipment) => !!shipment.forwarderName),
                'forwarderName'
            ),
            (results, value) => ({
                name: value,
                value: results.length
            })
        );

        let shipmentsByForwarder: pieChartData | undefined = undefined;
        if (!isForwarder) {
            shipmentsByForwarder = {
                data: shipmentsByForwarderData,
                filterObjectFn: (value: string) => [
                    {
                        field: 'forwarderName',
                        value: [value]
                    }
                ]
            };
        }
        const shipmentsByTypeData = map(groupBy(shipmentsData, 'typeCode'), (results, value) => ({
            name: eShipmentTypes[value],
            value: results.length
        }));
        const shipmentsByType: pieChartData = {
            data: shipmentsByTypeData,
            filterObjectFn: (value: string) => [
                {
                    field: 'typeCode',
                    value: [Object.keys(eShipmentTypes).find((key) => eShipmentTypes[key] === value)]
                }
            ]
        };

        if (!['FORWARDER', 'FREELANCER'].includes(userInfo.companyType)) {
            const shipmentsByVendorData = map(
                groupBy(
                    shipmentsData.filter((shipment) => !!shipment.shipperName).map((s) => ({ shipperName: s.shipperName?.toUpperCase() })),
                    'shipperName'
                ),
                (results, value) => ({
                    name: value,
                    value: results.length
                })
            );
            shipmentsByVendor = {
                hasLegend: false,
                data: shipmentsByVendorData,
                filterObjectFn: (value: string) => [
                    {
                        field: 'shipperName',
                        value
                    }
                ]
            };

            const calcTransCost = (transPrice?: number, goodsValue?: number) => {
                const transportationCost = Number(transPrice) / Number(goodsValue);
                return Number((transportationCost * 100).toFixed(2));
            };

            transportationCostAvg = meanBy(shipmentsWithTransCost, (s) => calcTransCost(s.transportationPrice, s.goodsValue));

            const allExpenses: Array<{ price: number; currency: string }> = [];
            shipmentsData.forEach((s) => {
                if (Number(s.transportationPrice) && s.transportationPriceCurrency)
                    allExpenses.push({ price: Number(s.transportationPrice), currency: s.transportationPriceCurrency });
                if (Number(s.goodsValue) && s.goodsValueCurrency) allExpenses.push({ price: Number(s.goodsValue), currency: s.goodsValueCurrency });
            });
            openInboundSpend = map(groupBy(allExpenses, 'currency'), (results, value) => ({
                currency: value,
                price: sumBy(results, 'price')
            }));
        }

        if (userInfo.companyType === 'FREELANCER') {
            let shipmentsByCargoOwnerData = map(
                groupBy(
                    shipmentsData
                        .filter((shipment) => !!shipment.shipperName && ['AI', 'AE'].includes(shipment.typeCode))
                        .map((s) => ({ shipperName: s.shipperName?.toUpperCase() })),
                    'shipperName'
                ),
                (results, value) => ({
                    name: value,
                    value: results.length
                })
            );
            shipmentsByCargoOwnerData.push(
                ...map(
                    groupBy(
                        shipmentsData
                            .filter((shipment) => !!shipment.consigneeName && ['OI', 'OEX', 'OE'].includes(shipment.typeCode))
                            .map((s) => ({ consigneeName: s.consigneeName?.toUpperCase() })),
                        'consigneeName'
                    ),
                    (results, value) => ({
                        name: value,
                        value: results.length
                    })
                )
            );
            shipmentsByCargoOwner = {
                hasLegend: false,
                data: shipmentsByCargoOwnerData,
                filterGridText: (value: string) => value
            };
            shipmentProfitAvg = meanBy(
                shipmentsData.filter((s) => !!s.buyingRate && !!s.sellingRate && s.buyingRateCurrency === s.sellingRateCurrency),
                (s) => s.buyingRate - s.sellingRate
            );
        }

        const shipmentsByCarrierData = map(
            groupBy(
                shipmentsData
                    .map((s) => ({
                        carrierName:
                            carriers.find((item) => item.carrierCode === s.carrierCode)?.carrierName?.toUpperCase() || s.carrierCode?.toUpperCase()
                    }))
                    .filter((v) => !!v.carrierName),
                'carrierName'
            ),
            (results, value) => ({
                name: value,
                value: results.length
            })
        );

        const shipmentsByCarrier: pieChartData = {
            data: shipmentsByCarrierData,
            hasLegend: false,
            filterObjectFn: (value: string) => [
                {
                    field: 'carrierCode',
                    value
                }
            ]
        };
        const shipmentsByRouteData = map(
            groupBy(
                shipmentsData
                    .map((s) => {
                        const originCountry = s.originCountry?.trim();
                        const destinationCountry = s.destinationCountry?.trim();

                        const originStation = s.originStation?.trim();
                        const destinationStation = s.destinationStation?.trim();

                        let route: string | null = `${originCountry}${originStation} - ${destinationCountry}${destinationStation}`;
                        if (!(originCountry && destinationCountry && originStation && destinationStation)) route = null;
                        return {
                            route
                        };
                    })
                    .filter((v) => !!v.route),
                'route'
            ),
            (results, value) => ({
                name: value,
                value: results.length
            })
        );
        const shipmentsByRoute: pieChartData = {
            data: shipmentsByRouteData,
            hasLegend: false,
            filterObjectFn: (value: string) => {
                const [originData, destinationData] = value.split(' - ');
                const originCountry = originData.slice(0, 2);
                const originStation = originData.slice(2);

                const destinationCountry = destinationData.slice(0, 2);
                const destinationStation = destinationData.slice(2);
                return [
                    {
                        field: 'originCountry',
                        value: originCountry
                    },
                    {
                        field: 'originStation',
                        value: originStation
                    },
                    {
                        field: 'destinationCountry',
                        value: destinationCountry
                    },
                    {
                        field: 'destinationStation',
                        value: destinationStation
                    }
                ];
            }
        };

        const shipmentsByCommodityData = map(
            groupBy(
                shipmentsData.filter((shipment) => !shipment.items?.length && !!shipment.commodity),
                'commodity'
            ),
            (results, value) => ({
                name: value,
                value: results.length
            })
        );
        const shipmentsByItemsData = map(
            groupBy(
                shipmentsData
                    .filter((shipment) => shipment.items?.length)
                    .flatMap((s) => {
                        return s.items?.map((item) => {
                            return { ...s, itemName: item.name };
                        });
                    }),
                'itemName'
            ),
            (results, value) => ({
                name: value,
                value: results.length
            })
        );

        let shipmentsByCommodity: pieChartData | undefined = undefined;
        if (!isForwarder) {
            shipmentsByCommodity = {
                data: [...shipmentsByCommodityData, ...shipmentsByItemsData],
                hasLegend: false,
                filterObjectFn: (value: string) => [
                    {
                        field: 'commodity',
                        value
                    }
                ]
            };
        }

        const calcTransitTime = (shipment: shipment) => {
            const { ata, atd, eta, etd } = shipment;
            const atdDate = moment(atd);
            const ataDate = moment(ata);
            const etdDate = moment(etd);
            const etaDate = moment(eta);

            if (atd && ata && atdDate.isValid() && ataDate.isValid()) return ataDate.diff(atdDate, 'days');
            if (etd && eta && etdDate.isValid() && etaDate.isValid()) return etaDate.diff(etdDate, 'days');

            return null;
        };
        const airTransitTimes = shipmentsData
            .filter((shipment) => ['AI', 'AE', 'DROP_AIR'].includes(shipment.typeCode))
            .map((s) => calcTransitTime(s))
            .filter((time) => time != null);
        const oceanTransitTimes = shipmentsData
            .filter((shipment) => ['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(shipment.typeCode))
            .map((s) => calcTransitTime(s))
            .filter((time) => time != null);

        const airAvgTransitTime = mean(airTransitTimes);
        const oceanAvgTransitTime = mean(oceanTransitTimes);

        return {
            activeShipments,
            deliveredShipments,
            currentMonthArrivals,
            missingDocuments: 0,
            starredShipments: followingShipments.length,
            warningAlertsCount: warningAlerts(),
            criticalAlertsCount: criticalAlerts(),
            transportationCostAvg: transportationCostAvg && Number(transportationCostAvg.toFixed(2)),
            openInboundSpend,
            shipmentProfitAvg,
            airAvgTransitTime: Number(airAvgTransitTime.toFixed(2)),
            oceanAvgTransitTime: Number(oceanAvgTransitTime.toFixed(2)),
            charts: {
                shipmentsByForwarder,
                shipmentsByType,
                shipmentsByVendor,
                shipmentsByCargoOwner,
                shipmentsByCarrier,
                // shipmentsByRoute
                shipmentsByCommodity
            }
        };
    }
);
const error = (state: RootState) => state.shipments.error;

export { default as shipmentsSelectors } from './selectors';

export default {
    shipmentsData,
    fetchShipmentsError,
    getShipmentById,
    getAlertsByShipmentId,
    getShipmentIsActiveById,
    settingsByLanguage,
    shipmentStateOptions,
    upcomingArrivalsShipments,
    readySoonShipments,
    orderShipments,
    getSummaryData,
    error
};
