import React, { useState, useEffect } from 'react';
import SingleShipmentPage from './SingleShipmentPage';
import SingleShipmentPageMobile from './SingleShipmentPageMobile';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { settings, shipment, shipmentSettingsFunc, FollowUp } from '../../../models/entities/shipment/shipment';
import { Dispatch, RootState } from '../../../state/store/store';
import Loader from '../../shared/SmallComponents/Loader';
import ExceptionPage from '../ExceptionPage/ExceptionPage';
import useWindowSize from '../../../hooks/windowSize';
import { TimeLineItem } from '../../shared/TimeLine/TimeLine';
import { singleShipmentSelectors } from '../../../state/ducks/singleShipment/selectors';
import { localizationSelectors } from '../../../state/ducks/localization/selectors';
import { connect } from 'react-redux';
import { shipmentsSelectors } from '../../../state/ducks/shipments/selectors';
import { headerSelectors } from '../../../state/ducks/header/selectors';
import { pagesHistorySelectors } from '../../../state/ducks/pagesHistory/selectors';
import { businessPartnersSelectors } from '../../../state/ducks/businessPartner/selectors';
import { settings as companySettings } from '../../../models/entities/businessPartner/businessPartner';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import claimsType from '../../../models/entities/claimsType';
import { bid } from '../../../models/entities/bid/bid';
import { drawers } from '../../../state/ducks/header/header';
import { claimsDomainsType } from '../../../userClaims';
import { purchaseOrderItem } from '../../../models/entities/purchaseOrder/purchaseOrderItem';
import { allowedCompany } from '../../../models/entities/user';
import { shipmentAlert } from '../../../models/entities/shipment/shipmentAlert';
import { userDetails } from '../../../state/ducks/users/users';

type PathParams = {
    shipmentId: string;
};

type Props = RouteComponentProps<PathParams> & {
    shipment: shipment | undefined;
    shipmentHistoryUsers: string[];
    setCurrentShipmentId: (currentShipmentId: string | null) => void;
    fetchSingleShipment: (shipmentId: string) => Promise<shipment>;
    fetchSendToSapGUID: (guid: string) => Promise<void>;
    fetchRelatedShipmentsById: (shipmentId: string) => Promise<shipment>;
    reStateShipmentById: (shipmentId: string) => Promise<shipment>;
    dismissShipmentAlert: (shipmentId: string, alert: shipmentAlert) => Promise<void>;
    getBPRemarksById: (id: string) => string;
    getBPRemarksAlertById: (id: string) => boolean;
    fetchShipmentError: any;
    isLoadingShipment: boolean;
    updateShipmentState: (shipmentId: string, shipmentState: string) => Promise<void>;
    updateShipmentAccountingState: (shipmentId: string, shipmentAccountingState: string) => Promise<void>;
    isDrawerOpen?: boolean;
    shipmentState?: string;
    shipmentStateTimelineData?: Array<TimeLineItem>;
    historyUrls: string[];
    localizations?: any;
    allowedCompanies: allowedCompany[];
    settings: shipmentSettingsFunc;
    companySettings?: companySettings;
    printMode?: boolean;
    dismissRequiredDoc: (shipmentId: string, docType: string) => string;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    isFreelancerOrForwarder?: boolean;
    shipmentIsLoading?: boolean;
    updateBid?: (id: string, bid: bid, shipment: shipment, publish: boolean) => Promise<boolean>;
    startBid?: (id: string, shipmentId: string, shipment: shipment) => Promise<void>;
    createBid: (shipment: shipment, bid: bid, publish: boolean) => Promise<string>;
    featureFlags?: featureFlagsModel;
    isAllowedShipment: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
    // New follow-up methods
    addNewFollowUp: (shipmentId: string, followUp: FollowUp) => Promise<void>;
    updateFollowUpCompletionStatus: (shipmentId: string, followUpId: number, isCompleted: boolean) => Promise<void>;
    deleteFollowUp: (shipmentId: string, followUpId: number) => Promise<void>;
    users: userDetails[];
    currentUserId: string;
};

const SingleShipmentPageConnected = ({
    shipment,
    shipmentHistoryUsers,
    setCurrentShipmentId,
    fetchSingleShipment,
    fetchSendToSapGUID,
    dismissShipmentAlert,
    updateShipmentState,
    updateShipmentAccountingState,
    isDrawerOpen,
    fetchShipmentError,
    shipmentState,
    isLoadingShipment,
    shipmentStateTimelineData,
    historyUrls,
    getBPRemarksById,
    getBPRemarksAlertById,
    localizations,
    allowedCompanies,
    match,
    settings,
    companySettings,
    printMode,
    shipmentIsLoading,
    dismissRequiredDoc,
    getUserClaims,
    isFreelancerOrForwarder,
    reStateShipmentById,
    updateBid,
    createBid,
    startBid,
    featureFlags,
    isAllowedShipment,
    setDrawerOpen,
    fetchRelatedShipmentsById,
    addNewFollowUp,
    updateFollowUpCompletionStatus,
    deleteFollowUp,
    users,
    currentUserId
}: Props) => {
    const [tags, setTags] = useState(['tag1', 'long tag', 'another tag example']);

    const [windowWidth] = useWindowSize();

    const onTagRemove = (tag: string) => {
        setTags(tags.filter((current) => current !== tag));
    };

    const urlShipmentId = match.params.shipmentId;

    const parsedError = fetchShipmentError ? JSON.parse(fetchShipmentError) : null;

    useEffect(() => {
        setCurrentShipmentId(urlShipmentId);
        fetchSingleShipment(urlShipmentId).then(() => {
            fetchRelatedShipmentsById(urlShipmentId);
        });
    }, [urlShipmentId, setCurrentShipmentId, fetchSingleShipment, fetchRelatedShipmentsById]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.getAll('windowPrint').length > 0 && shipment?.id && !shipmentIsLoading) {
            window.print();
        }
    }, [shipment?.id, shipmentIsLoading]);

    return (
        <>
            {!fetchShipmentError ? (
                shipment && !shipmentIsLoading ? (
                    windowWidth >= 768 ? (
                        <SingleShipmentPage
                            isAllowedShipment={isAllowedShipment}
                            shipment={shipment}
                            shipmentTags={tags}
                            shipmentState={shipmentState}
                            shipmentStateTimelineData={shipmentStateTimelineData}
                            updateShipmentState={updateShipmentState}
                            updateShipmentAccountingState={updateShipmentAccountingState}
                            dismissShipmentAlert={dismissShipmentAlert}
                            onTagRemoveClicked={onTagRemove}
                            isDrawerOpen={isDrawerOpen}
                            getBPRemarksById={getBPRemarksById}
                            getBPRemarksAlertById={getBPRemarksAlertById}
                            localization={localizations}
                            isLoadingShipment={isLoadingShipment}
                            allowedCompanies={allowedCompanies}
                            settings={settings(shipment)}
                            historyUrls={historyUrls}
                            companySettings={companySettings}
                            printMode={printMode}
                            dismissRequiredDoc={dismissRequiredDoc}
                            getUserClaims={getUserClaims}
                            isFreelancerOrForwarder={isFreelancerOrForwarder}
                            reStateShipmentById={reStateShipmentById}
                            fetchSendToSapGUID={fetchSendToSapGUID}
                            updateBid={updateBid}
                            createBid={createBid}
                            startBid={startBid}
                            featureFlags={featureFlags}
                            setDrawerOpen={setDrawerOpen}
                            // Pass follow-up events
                            addNewFollowUp={addNewFollowUp}
                            updateFollowUpCompletionStatus={updateFollowUpCompletionStatus}
                            deleteFollowUp={deleteFollowUp}
                            users={users}
                            currentUserId={currentUserId}
                        />
                    ) : (
                        <SingleShipmentPageMobile
                            shipment={shipment}
                            shipmentTags={tags}
                            onTagRemoveClicked={onTagRemove}
                            updateShipmentAccountingState={updateShipmentAccountingState}
                            localization={localizations}
                            settings={settings(shipment)}
                            getUserClaims={getUserClaims}
                            companySettings={companySettings}
                            updateBid={updateBid}
                            createBid={createBid}
                            startBid={startBid}
                            featureFlags={featureFlags}
                            setDrawerOpen={setDrawerOpen}
                            isFreelancerOrForwarder={isFreelancerOrForwarder}
                            // Pass follow-up events to mobile as well if needed
                            addNewFollowUp={addNewFollowUp}
                            updateFollowUpCompletionStatus={updateFollowUpCompletionStatus}
                            deleteFollowUp={deleteFollowUp}
                            users={users}
                            currentUserId={currentUserId}
                        />
                    )
                ) : (
                    <Loader />
                )
            ) : (
                <ExceptionPage status={parsedError.status} subTitle={parsedError.message} localization={localizations} goBackPath="/shipments" />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    shipment: singleShipmentSelectors.shipment(state),
    isAllowedShipment: singleShipmentSelectors.isAllowedShipment(state),
    shipmentState: singleShipmentSelectors.shipmentState(state),
    shipmentStateTimelineData: singleShipmentSelectors.shipmentStateTimelineData(state),
    parties: singleShipmentSelectors.parties(state),
    events: singleShipmentSelectors.events(state),
    documents: singleShipmentSelectors.documents(state),
    discussions: singleShipmentSelectors.discussions(state),
    shipmentHistoryUsers: singleShipmentSelectors.shipmentHistoryUsers(state),
    fetchShipmentError: singleShipmentSelectors.fetchShipmentError(state),
    isLoadingShipment: singleShipmentSelectors.isLoadingShipment(state),
    followers: singleShipmentSelectors.shipment(state)?.followers,
    localizations: localizationSelectors.singleShipmentPage(state),
    settings: shipmentsSelectors.settingsByLanguage(state),
    companySettings: businessPartnersSelectors.settingsByLanguage(state),
    isDrawerOpen: headerSelectors.drawerOpen(state),
    historyUrls: pagesHistorySelectors.historyUrls(state),
    printMode: headerSelectors.printMode(state),
    getBPRemarksById: (id: string) => businessPartnersSelectors.getBPRemarksById(state)(id),
    getBPRemarksAlertById: (id: string) => businessPartnersSelectors.getBPRemarksAlertById(state)(id),
    isFreelancerOrForwarder: userSelectors.isFreelancerOrForwarder(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain),
    allowedCompanies: userSelectors.allowedCompanies(state),
    shipmentIsLoading: state.loading.effects.shipments.reStateShipmentById,
    featureFlags: userSelectors.featureFlags(state),
    users: userSelectors.usersInSameNetworkIncludeSelf(state),
    currentUserId: state.user.userInfo.id
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCurrentShipmentId: (currentShipmentId: string | null) => dispatch.singleShipment.setCurrentShipmentId(currentShipmentId),
    fetchSingleShipment: (shipmentId: string) => dispatch.shipments.fetchShipmentById(shipmentId),
    fetchRelatedShipmentsById: (shipmentId: string) => dispatch.shipments.fetchRelatedShipmentsById(shipmentId),
    reStateShipmentById: (shipmentId: string) => dispatch.shipments.reStateShipmentById(shipmentId),
    updateShipmentState: (shipmentId: string, shipmentState: string, forwarderId?: string) =>
        dispatch.shipments.updateShipmentState({ shipmentId, shipmentState, forwarderId }),
    updateShipmentAccountingState: (shipmentId: string, shipmentAccountingState: string, forwarderId?: string) =>
        dispatch.shipments.updateShipmentAccountingState({ shipmentId, shipmentAccountingState, forwarderId }),
    dismissShipmentAlert: (shipmentId: string, alert: shipmentAlert) => dispatch.shipments.dismissShipmentAlert({ shipmentId, alert }),
    dismissRequiredDoc: (shipmentId: string, docType: string) => dispatch.shipments.dismissRequiredDoc({ shipmentId, docType }),
    updateBid: (id: string, bid: string, shipment: shipment, publish: boolean) => dispatch.bids.updateBid({ id, bid, publish, shipment }),
    createBid: (shipment: shipment, bid: bid, publish: boolean) => dispatch.bids.createBid({ shipment, bid, publish }),
    startBid: (id: string, shipmentId: string, shipment: shipment) => dispatch.bids.startBid({ id, shipmentId, shipment }),
    setDrawerOpen: (open: drawers, params?: any) => dispatch.header.setDrawerOpenParams({ open, params }),
    fetchSendToSapGUID: (guid: string) => dispatch.shipments.fetchSendToSapGUID(guid),
    // New follow-up events
    addNewFollowUp: (shipmentId: string, followUp: FollowUp) => dispatch.shipments.addNewFollowUp({ shipmentId, followUp }),
    updateFollowUpCompletionStatus: (shipmentId: string, followUpId: number, isCompleted: boolean) =>
        dispatch.shipments.updateFollowUpCompletionStatus({ shipmentId, followUpId, isCompleted }),
    deleteFollowUp: (shipmentId: string, followUpId: number) => dispatch.shipments.deleteFollowUp({ shipmentId, followUpId })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(SingleShipmentPageConnected));
