import React from 'react';
import { connect } from 'react-redux';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { RootState } from '../../../../state/store/store';

type Props = {
    shipment: shipment;
};

const QuantityCellRenderer = ({ shipment }: Props) => {
    const count = Number(shipment.quantity) || shipment.counts?.itemCount || shipment.counts?.packageCount || shipment.counts?.containerCount;
    return <>{count}</>;
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuantityCellRenderer);
