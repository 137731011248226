import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ShipmentsPage from './ShipmentsPage';
import { shipment, settings, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import { RootState } from '../../../../state/store/store';
import { filterFieldsType, gridSortState } from '../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { allShipmentsPageSelectors } from '../../../../state/ducks/allShipmentsPage/selectors';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { ColumnType } from '../../../shared/Grid/types/Column';
import ExceptionPage from '../../ExceptionPage/ExceptionPage';
import { filterColsByUserSettings } from '../../../shared/Grid/util';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { userSettings } from '../../../../models/entities/user';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import { claimsDomainsType } from '../../../../userClaims';
import claimsType from '../../../../models/entities/claimsType';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
type Props = {
    shipments: Array<shipment>;
    fetchShipmentsError: any;
    gridColumns: Array<ColumnType<shipment>>;
    currentGridSort: gridSortState | null;
    localizations: any;
    showLoader?: boolean;
    activeShipmentsCount: number;
    shipmentsGridSettings?: userSettings;
    lastVisitShipmentId?: string;
    filterFields: filterFieldsType | null;
    fetchShipments: () => void;
    fetchGridColumns: () => void;
    setGridSort: (sortData: gridSortState) => void;
    settings: shipmentSettingsFunc;
    fetchShipmentsByFilter: (filter: filterFieldsType) => Array<shipment>;
    resetShipmentFilter: () => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    featureFlags?: featureFlagsModel;
    bulkUpdateShipmentState: (payload: { shipmentIds: string[]; state: string }) => Promise<void>;
};
const ShipmentsPageConnected = ({
    shipments,
    fetchShipmentsError,
    gridColumns,
    currentGridSort,
    localizations,
    showLoader,
    shipmentsGridSettings,
    lastVisitShipmentId,
    filterFields,
    activeShipmentsCount,
    fetchShipments,
    fetchGridColumns,
    setGridSort,
    settings,
    resetShipmentFilter,
    fetchShipmentsByFilter,
    getUserClaims,
    featureFlags,
    bulkUpdateShipmentState
}: Props) => {
    useEffect(() => {
        if (!filterFields?.find((f: { field: string; value: string }) => f.field === 'isActive')) {
            fetchShipmentsByFilter([]);
        }
        fetchGridColumns();
    }, [fetchShipments, fetchGridColumns]);

    useEffect(() => {
        const activeFilter = filterFields?.filter(
            (f: { field: string; value: string }) => f.field === 'isActive' || (f.field === 'salesman' && f.value.length > 0)
        );
        if (activeFilter) {
            fetchShipmentsByFilter(activeFilter);
        }
    }, [filterFields]);
    const DEFAULT_SORT_DIRECTION = 'asc';
    const SECOND_SORT_DIRECTION = 'desc';

    const onGridColumnClick = (newColumn: keyof shipment) => {
        if (currentGridSort) {
            const { direction, column } = currentGridSort;
            if (column === newColumn)
                if (direction === DEFAULT_SORT_DIRECTION)
                    setGridSort({
                        column: newColumn,
                        direction: SECOND_SORT_DIRECTION
                    });
                else
                    setGridSort({
                        column: newColumn,
                        direction: DEFAULT_SORT_DIRECTION
                    });
            else {
                setGridSort({
                    column: newColumn,
                    direction: DEFAULT_SORT_DIRECTION
                });
            }
        } else {
            setGridSort({
                column: newColumn,
                direction: DEFAULT_SORT_DIRECTION
            });
        }
    };
    const parsedError = fetchShipmentsError ? JSON.parse(fetchShipmentsError) : null;
    const userSettingKey = 'ShipmentGrid';
    return !fetchShipmentsError ? (
        <ShipmentsPage
            shipments={shipments}
            bulkUpdateShipmentState={bulkUpdateShipmentState}
            fetchShipments={fetchShipments}
            gridColumns={filterColsByUserSettings(gridColumns, shipmentsGridSettings)}
            onGridColumnClick={onGridColumnClick}
            gridSortDirection={currentGridSort?.direction}
            gridSortedBy={currentGridSort?.column}
            localization={localizations}
            showLoader={showLoader}
            activeShipmentsCount={activeShipmentsCount}
            settings={settings}
            userSettingKey={userSettingKey}
            lastVisitShipmentId={lastVisitShipmentId}
            resetFilterFunc={filterFields && filterFields.length > 0 && shipments.length === 0 ? resetShipmentFilter : undefined}
            getUserClaims={getUserClaims}
        />
    ) : (
        <ExceptionPage status={parsedError.status} subTitle={parsedError.message} goBackPath="/" />
    );
};

const mapStateToProps = (state: RootState) => ({
    activeShipmentsCount: allShipmentsPageSelectors.activeShipmentsCount(state),
    shipments: allShipmentsPageSelectors.currentGridDataPage(state),
    fetchShipmentsError: shipmentsSelectors.fetchShipmentsError(state),
    gridColumns: allShipmentsPageSelectors.gridColumns(state),
    currentGridSort: allShipmentsPageSelectors.gridSort(state),
    localizations: localizationSelectors.allShipmentsPage(state),
    showLoader:
        state.loading.effects.allShipmentsPage.fetchGridColumns ||
        state.loading.effects.shipments.fetchShipments ||
        state.loading.effects.allShipmentsPage.fetchShipmentsByFilter,
    settings: shipmentsSelectors.settingsByLanguage(state),
    shipmentsGridSettings: userSelectors.shipmentsGridSettings(state),
    lastVisitShipmentId: singleShipmentSelectors.currentShipmentId(state) || undefined,
    filterFields: allShipmentsPageSelectors.filterFields(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain),
    featureFlags: userSelectors.featureFlags(state)
});
const mapDispatchToProps = (dispatch: any) => ({
    fetchShipments: () => dispatch.shipments.fetchShipments(),
    fetchGridColumns: () => dispatch.allShipmentsPage.fetchGridColumns(),
    setGridSort: (sortData: gridSortState) => dispatch.allShipmentsPage.setGridSort(sortData),
    resetShipmentFilter: () => dispatch.allShipmentsPage.setShipmentFilters({ filter: [] }),
    fetchShipmentsByFilter: (filter: filterFieldsType) => dispatch.allShipmentsPage.fetchShipmentsByFilter(filter),
    bulkUpdateShipmentState: (payload: { shipmentIds: string[]; state: string }) => dispatch.allShipmentsPage.bulkUpdateShipmentState(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsPageConnected);
