import React from 'react';
import { DatePicker } from 'formik-antd';
import moment from 'moment';
import styled from 'styled-components';
import { FormikErrors, FormikTouched } from 'formik';

type Props = {
    value?: Date;
    style?: any;
    placeHolder?: string;
    onChange?: any;
    error?: FormikErrors<Date> | undefined;
    touched?: FormikTouched<Date> | undefined;
    name: string;
    showTime?: boolean;
};

const CZDatePicker = (props: Props) => {
    const hasError = props.touched && props.error !== undefined;
    return (
        <Conatiner hasError={hasError}>
            <DatePicker
                name={props.name}
                placeholder={props.placeHolder}
                style={{ ...props.style }}
                // Only format with moment if value exists
                value={props.value ? moment(props.value) : undefined}
                showTime={props.showTime}
            />
            {hasError && <ErrorMessage>{props.error}</ErrorMessage>}
        </Conatiner>
    );
};

// Default props to avoid undefined errors
CZDatePicker.defaultProps = {
    style: {},
    placeHolder: 'Select date'
};

type ContainerProps = {
    hasError: boolean | undefined;
};

const ErrorMessage = styled.div`
    position: absolute;
    top: 27px;
    font-size: 11px;
    color: red;
`;

const Conatiner = styled.div<ContainerProps>`
    position: relative;
    & .ant-picker {
        border-radius: 70px !important;
        border-color: ${(props) => (props.hasError ? 'red' : props.theme.colors.primaryBlue)} !important;
    }
`;

export default CZDatePicker;
