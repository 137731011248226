import React from 'react';
import styled from 'styled-components';

interface TabItem {
    key: string;
    label: React.ReactNode;
}

interface TabsProps {
    items: TabItem[];
    activeKey: string;
    onChange: (activeKey: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ items, activeKey, onChange }) => {
    return (
        <TabsContainer>
            <TabList>
                {items.map((item) => (
                    <TabItem key={item.key} isActive={item.key === activeKey} onClick={() => onChange(item.key)}>
                        {item.label}
                    </TabItem>
                ))}
            </TabList>
        </TabsContainer>
    );
};

export default Tabs;

const TabsContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
`;

const TabList = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

interface TabItemProps {
    isActive: boolean;
}

const TabItem = styled.div<TabItemProps>`
    padding: 8px 16px;
    margin-right: 16px;
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? '600' : '400')};
    color: ${(props) => (props.isActive ? '#1890ff' : 'inherit')};
    border-bottom: ${(props) => (props.isActive ? '2px solid #1890ff' : 'none')};
    transition: all 0.3s;

    &:hover {
        color: #40a9ff;
    }
`;
