import { ModelConfig } from '@rematch/core';
import { CarrierWeightPricing } from '../../../models/entities/admin/carrierWeightPricing';
import { localSDK as client } from '../../../sdk';
import { RootState } from '../../store/store';

export type CarrierWeightPricingType = {
    carrierWeightPricing: CarrierWeightPricing[];
    seletedCarrierCode?: string;
};

export const carrierWeightPricing: ModelConfig<CarrierWeightPricingType> = {
    state: {
        carrierWeightPricing: [],
        seletedCarrierCode: undefined
    },
    reducers: {
        setCarrierWeightPricing(state: CarrierWeightPricingType, carrierWeightPricing: CarrierWeightPricing[]): CarrierWeightPricingType {
            return { ...state, carrierWeightPricing };
        },
        setSelectedCarrierCode(state: CarrierWeightPricingType, carrierCode: string): CarrierWeightPricingType {
            return { ...state, seletedCarrierCode: carrierCode };
        }
    },
    effects: (dispatch: any) => ({
        async fetchCarrierWeightPricing(carrierCode: string) {
            const carrierWeightPricing = await client.carrierWeightPricing().fetchCarrierWeightPricing(carrierCode);
            dispatch.carrierWeightPricing.setCarrierWeightPricing(carrierWeightPricing);
        },
        async updateCarrierWeightPricing(
            {
                carrierWeightPricing
            }: {
                carrierWeightPricing: CarrierWeightPricing[];
            },
            rootState: RootState
        ) {
            await client.carrierWeightPricing().updateCarrierWeightPricing(carrierWeightPricing);
            dispatch.carrierWeightPricing.fetchCarrierWeightPricing(rootState.carrierWeightPricing.seletedCarrierCode);
        }
    })
};
