import React, { useState, useEffect } from 'react';
import { Checkbox, DropDown, TextArea } from '../../../shared/inputs/base';
import Grid from '../../../shared/Grid/Grid';
import FormFieldLabel from '../../../shared/Form/FormFieldLabel';
import { ColumnType } from '../../../shared/Grid/types/Column';
import styled from 'styled-components';

import Button from '../../../shared/SmallComponents/Button';
import Input from '../../../shared/SmallComponents/Input';
import Modal from '../../../shared/Modal/Modal';
import { userDetails } from '../../../../state/ducks/users/users';
import { UserDropDownForm } from '../../../shared/inputs/form';
import { Formik, Form, Field, FieldProps } from 'formik';
import CZDatePicker from '../../../shared/SmallComponents/DatePicker';
import { FollowUp, shipment } from '../../../../models/entities/shipment/shipment';
import data from '../../../../sideEffects/company/settings/mockData';
interface IFollowUpExtended extends FollowUp {
    state: string;
    previousState: string;
}
type Props = {
    shipment: shipment;
    addNewFollowUp: (shipmentId: string, followUp: FollowUp) => Promise<void>;
    updateFollowUpCompletionStatus: (shipmentId: string, followUpId: number, isCompleted: boolean) => Promise<void>;
    deleteFollowUp: (shipmentId: string, followUpId: number) => Promise<void>;
    users: userDetails[];
    currentUserId: string;
};

const FollowUpTab = ({ shipment, addNewFollowUp, updateFollowUpCompletionStatus, deleteFollowUp, users, currentUserId }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [followUps, setFollowUps] = useState<Array<IFollowUpExtended>>([]);
    const followupGridColumns: ColumnType<IFollowUpExtended>[] = [
        {
            title: 'To Do',
            field: 'todo',
            sortable: true,
            isDisplayed: true,
            width: '250px'
        },
        {
            title: 'Current State',
            field: 'state',
            sortable: true,
            isDisplayed: true,
            width: '150px'
        },
        {
            title: 'Previous State',
            field: 'previousState',
            sortable: true,
            isDisplayed: true,
            width: '150px'
        },
        {
            title: 'Due Date',
            field: 'dueDate',
            sortable: true,
            isDisplayed: true,
            width: '150px',
            columnType: 'date'
        },
        {
            title: 'Assigned To',
            field: 'assignedTo',
            sortable: true,
            isDisplayed: true,
            width: '200px'
        },
        {
            title: 'Completed',
            field: 'isCompleted',
            sortable: true,
            isDisplayed: true,
            width: '120px',
            // columnType: 'bool',
            bindedFields: ['id', 'isCompleted'],
            renderer(cellValue, bindedData) {
                return (
                    <Checkbox
                        checked={(bindedData as any)[1] as boolean}
                        onChange={(e) => updateFollowUpCompletionStatus(shipment.id, (bindedData as any)[0], e.target.checked)}
                    />
                );
            }
        }
    ];

    const setInitialValues = () => ({
        todo: '',
        dueDate: new Date(),
        assignedTo: currentUserId
    });

    const validate = (values: any) => {
        const errors: any = {};
        if (!values.todo) {
            errors.todo = 'Task is required';
        }
        if (!values.dueDate) {
            errors.dueDate = 'Due Date is required';
        }
        if (!values.assignedTo) {
            errors.assignedTo = 'Assignee is required';
        }
        return errors;
    };
    const submit = async (values: any) => {
        await addNewFollowUp(shipment.id, values);
        setIsModalOpen(false);
    };
    useEffect(() => {
        const userMap: Map<string, userDetails> = new Map();

        users.forEach((user) => {
            userMap.set(user.id, user);
        });
        const eventsMap: Map<string, string> = new Map();
        data.en.state.forEach((state) => {
            eventsMap.set(state.code, state.name);
        });
        const followUps = shipment.followUps
            ? shipment.followUps.map((followUp) => {
                  const user = userMap.get(followUp.assignedTo);
                  const assignedTo = user ? `${user.firstName} ${user.lastName}` : 'N/A';
                  const followUpExtended: IFollowUpExtended = {
                      ...followUp,
                      state: eventsMap.get(shipment.state) || 'N/A',
                      previousState: eventsMap.get(shipment.previousState) || 'N/A',
                      assignedTo
                  };
                  return followUpExtended;
              })
            : [];
        setFollowUps(followUps);
    }, [shipment.followUps, shipment.events, shipment.state]);
    return (
        <PageWrapper>
            <HeaderContainer>
                <h2>Follow-up Tasks</h2>
                <Button onClick={() => setIsModalOpen(true)}>Add New Follow-up</Button>
            </HeaderContainer>
            <GridWrapper>
                {shipment.followUps?.length ? (
                    <Grid<IFollowUpExtended>
                        columns={followupGridColumns}
                        data={followUps}
                        idKey="id"
                        emptyStateText="No follow-up tasks found for this shipment"
                    />
                ) : (
                    <CenterContainer>
                        <FormFieldLabel text="No follow-up tasks found for this shipment" />
                    </CenterContainer>
                )}
            </GridWrapper>

            <Modal
                title={{ text: 'Add Follow up Task' }}
                width="600px"
                isOpen={isModalOpen}
                onCancelCallback={() => setIsModalOpen(false)}
                Content={() => {
                    return (
                        <ModalContent>
                            <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
                                {() => {
                                    return (
                                        <Form>
                                            <FormGroup>
                                                <FormFieldLabel text="Task" />
                                                <Field name="todo">
                                                    {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                                                        <TextArea
                                                            name="todo"
                                                            value={value}
                                                            autoSize={{ minRows: 2 }}
                                                            width="300px"
                                                            onChange={(e: any) => {
                                                                setFieldValue('todo', e);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormFieldLabel text="Due Date" />
                                                <Field name="dueDate">
                                                    {({ field, meta }: FieldProps) => (
                                                        <CZDatePicker
                                                            name="dueDate"
                                                            style={{ width: '300px' }}
                                                            placeHolder="Select Due Date"
                                                            value={field.value}
                                                            error={meta.error}
                                                            touched={meta.touched}
                                                        />
                                                    )}
                                                </Field>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormFieldLabel text="Assign To" />
                                                <UserDropDownForm
                                                    name="assignedTo"
                                                    placeHolder={'Select Assignee'}
                                                    users={users}
                                                    customUsers
                                                    showSearch
                                                />
                                            </FormGroup>
                                            <ButtonContainer>
                                                <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                                                <Button type="submit">Save</Button>
                                            </ButtonContainer>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </ModalContent>
                    );
                }}
            />
        </PageWrapper>
    );
};

export default FollowUpTab;

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
    width: 100%;
`;

const ModalContent = styled.div`
    padding: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;

const GridWrapper = styled.div`
    flex: 1;
    overflow: auto;
    width: 100%;
    z-index: 1;
`;

const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;
