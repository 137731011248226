import React from 'react';

import { FileUploader } from 'react-drag-drop-files';
import styled from 'styled-components';
type Props = {
    allowedFileTypes?: string[];
    updateFiles: (files: File[] | File) => void;
    multiple?: boolean;
};

const UploadDnD = React.forwardRef(({ allowedFileTypes, updateFiles, multiple }: Props) => {
    const handleChange = (files: File[] | File) => {
        // if array of files
        if (Array.isArray(files)) {
            updateFiles([...files]);
        } else {
            updateFiles(files);
        }
    };
    return (
        <FileUploader multiple={multiple} handleChange={handleChange} name="file">
            <UploadFileContainer>
                <span>Upload or Drag and drop files here</span>
            </UploadFileContainer>
        </FileUploader>
    );
});
export default UploadDnD;
const UploadFileContainer = styled.div`
    height: 100px;
    width: 100%;
    border: 3px dotted #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
