import { Checkbox } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ColumnType } from '../../components/shared/Grid/types/Column';
import { shipment } from '../../models/entities/shipment/shipment';

const mockColumns: Array<ColumnType<shipment>> = [
    {
        title: 'Shipment Number',
        field: 'CargoZoneNumber',
        bindedFields: ['id'],
        width: '100px',
        sortable: true,
        isDisplayed: true,
        textAlign: 'center',
        columnType: 'shipmentLink'
    },
    {
        field: 'alertStatus',
        width: '40px',
        sortable: true,
        columnType: 'shipmentAlertStatus',
        textAlign: 'center',
        toolTipText: `The risk indicator will be yellow (!) when your attention or action is needed to promote the shipment's progress.
        Red (X), when a significant risk or potential disruption was detected. Green (V) means all good so far.`,
        bindedFields: ['id']
    },
    {
        title: 'Type',
        field: 'typeCode',
        bindedFields: ['subTypeCode'],
        width: '70px',
        sortable: true,
        columnType: 'shipmentType',
        textAlign: 'center'
    },
    {
        title: 'Trans. cost',
        field: 'transportationCost',
        width: '100%',
        columnType: 'transportation_cost',
        sortable: true
    },
    {
        title: 'Forwarder Inv. Paid',
        field: 'transportationPaid',
        width: '100%',
        renderer: (cellValue) => (
            <GreenCheckBoxContainer>
                <Checkbox checked={!!cellValue} />
            </GreenCheckBoxContainer>
        ),
        sortable: true
    },
    {
        title: 'Last Update',
        field: 'updatedAt',
        width: '100%',
        sortable: true,
        toolTipText: 'Displays the date & time of the last update was received',
        columnType: 'date-time'
    },
    {
        title: 'Open Date',
        field: 'createdAt',
        width: '100px',
        sortable: true,
        isDisplayed: true,
        toolTipText: 'Displays the date and time the shipment was created ',
        columnType: 'date'
    },
    {
        title: 'Company',
        field: 'companyName',
        bindedFields: ['companyId'],
        width: '100%',
        sortable: true,
        isDisplayed: true,
        columnType: 'company'
    },
    {
        title: 'Forwarder',
        field: 'forwarderName',
        width: '100px',
        sortable: true
    },
    {
        title: 'Client Ref',
        field: 'clientRef',
        width: '100px',
        toolTipText: `Cargo Owner's reference number`,
        sortable: true
    },
    {
        title: 'Forwarder Ref',
        field: 'forwarderRef',
        width: '100px',
        sortable: true
    },
    {
        title: 'Incoterms',
        field: 'incoterms',
        width: '80px',
        sortable: true,
        textAlign: 'center'
    },
    {
        title: 'Origin',
        field: 'originStation',
        width: '90px',
        sortable: true,
        bindedFields: ['originCountry', 'originStationName'],
        columnType: 'origin',
        toolTipText: 'Port of Loading'
    },
    {
        title: 'Destination',
        field: 'destinationStation',
        width: '90px',
        sortable: true,
        bindedFields: ['destinationCountry', 'destinationStationName'],
        columnType: 'destination',
        toolTipText: 'Port of Discharge'
    },
    {
        title: 'Shipper',
        field: 'shipperName',
        width: '100%',
        sortable: true
    },
    {
        title: 'Consignee',
        field: 'consigneeName',
        width: '100%',
        sortable: true
    },
    {
        title: 'Created by',
        field: 'userName',
        width: '100%',
        sortable: true
    },
    {
        title: 'Quantity',
        field: 'quantity',
        width: '100%',
        sortable: true,
        toolTipText: 'Number of total packing units in the shipment',
        textAlign: 'center',
        columnType: 'quantity'

    },
    {
        title: 'Weight',
        field: 'weight',
        width: '100%',
        sortable: true,
        columnType: 'weight',
        bindedFields: ['weight', 'weightUnit']
    },
    {
        title: 'Volume',
        field: 'volume',
        width: '100%',
        sortable: true,
        columnType: 'volume',
        bindedFields: ['volume', 'volumeUnit']
    },
    {
        title: 'Carrier',
        field: 'carrierCode',
        width: '100%',
        sortable: true,
        toolTipText: 'The physical/Actual transporter service provider (airline,shipping line, trucking company etc..)'
    },
    {
        title: 'Container#',
        field: 'containerNumber',
        width: '100%',
        sortable: true
    },
    {
        title: 'Commodity',
        field: 'commodity',
        width: '100%',
        sortable: true
    },
    {
        title: 'ETA',
        field: 'eta',
        width: '100%',
        sortable: true,
        columnType: 'date'
    },
    {
        title: 'ETD',
        field: 'etd',
        width: '100%',
        sortable: true,
        columnType: 'date',
        isDisplayed: false
    },
    {
        title: 'ATD',
        field: 'atd',
        width: '100%',
        sortable: true,
        columnType: 'date',
        isDisplayed: false
    },
    {
        title: 'ATA',
        field: 'ata',
        width: '100%',
        sortable: true,
        columnType: 'date',
        isDisplayed: false
    },
    {
        title: 'Pick up',
        field: 'pickUp',
        width: '100%',
        sortable: true,
        columnType: 'date'
    },
    {
        title: 'Goods Ready',
        field: 'goodsReady',
        width: '100%',
        sortable: true,
        columnType: 'date'
    },
    {
        title: 'State',
        field: 'state',
        width: '200px',
        sortable: true,
        columnType: 'shipment_state_name',
        toolTipText: 'Displays current state of the shipment (Generally)',
        bindedFields: ['state']
    },
    {
        title: 'Last event',
        field: 'lastEvent',
        width: '100%',
        sortable: true,
        columnType: 'event_name',
        toolTipText: 'Displays the latest event of the shipment (Detailed)',
        bindedFields: ['lastEvent']
    },
    {
        title: 'Operator',
        field: 'operator',
        width: '100%',
        textAlign: 'center',
        sortable: true,
        isDisplayed: true
    },
    {
        title: 'Customs File',
        field: 'customsFile',
        width: '100%',
        textAlign: 'center',
        sortable: true,
        isDisplayed: true
    },
    {
        title: 'Tracking Number',
        field: 'forwarderUniqueId',
        width: '100px',
        textAlign: 'center',
        sortable: true,
        isDisplayed: true
    },
    {
        title: 'Cargo Owner',
        field: 'cargoOwnerName',
        width: '100px',
        textAlign: 'center',
        sortable: true,
        isDisplayed: true
    },
    {
        title: 'Follow ups',
        field: 'followUps',
        width: '100px',
        sortable: true,
        isDisplayed: true,
        textAlign: 'center',
        columnType: 'followUps'
    },
];

const GreenCheckBoxContainer = styled.div`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #629e31;
        border-color: #629e31;
    }
`;

export default mockColumns;
