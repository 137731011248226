import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import claimsType from '../../../../models/entities/claimsType';
import { shipment, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import { claimsDomainsType } from '../../../../userClaims';
import Grid from '../../../shared/Grid/Grid';
import { ColumnType } from '../../../shared/Grid/types/Column';
import Loader from '../../../shared/SmallComponents/Loader';
import { styled } from '../../../shared/Theme/theme';
import AllShipmentsHeader from '../components/AllShipmentsHeader';
import ShipmentStateSelect from '../components/ShipmentStateSelect';

type Props = {
    shipments: Array<shipment>;
    gridColumns: Array<ColumnType<shipment>>;
    gridSortedBy?: keyof shipment;
    gridSortDirection?: 'asc' | 'desc';
    localization: any;
    showLoader?: boolean;
    userSettingKey?: string;
    lastVisitShipmentId?: string;
    activeShipmentsCount: number;
    fetchShipments: () => void;
    onGridColumnClick?: (column: keyof shipment) => void;
    settings: shipmentSettingsFunc;
    resetFilterFunc?: () => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    featureFlags?: featureFlagsModel;
    bulkUpdateShipmentState: (payload: { shipmentIds: string[]; state: string }) => Promise<void>;
};

const ShipmentsPage = ({
    shipments,
    gridColumns,
    gridSortedBy,
    gridSortDirection,
    localization,
    showLoader,
    userSettingKey,
    lastVisitShipmentId,
    activeShipmentsCount,
    fetchShipments,
    onGridColumnClick,
    settings,
    resetFilterFunc,
    getUserClaims,
    featureFlags,
    bulkUpdateShipmentState
}: Props) => {
    const [selectedShipments, setSelectedShipments] = useState<string[]>([]);
    const settingsState = settings().state;
    const bulkUpdateShipments = async (state: string) => {
        await bulkUpdateShipmentState({ shipmentIds: selectedShipments, state });
        setSelectedShipments([]);
    };

    return (
        <PageWrapper>
            {!!showLoader && activeShipmentsCount > 1 && !!gridColumns.length && (
                <LoadingOverlay>
                    <Loader width="20px" marginTop="0" inline />
                </LoadingOverlay>
            )}
            <AllShipmentsHeader fetchShipments={fetchShipments} isLoading={showLoader} getUserClaims={getUserClaims} featureFlags={featureFlags} />
            <GridWrapper>
                <Grid<shipment>
                    showCheckboxSelection={true}
                    selectedRows={selectedShipments}
                    onSelectedRowsChange={(selectedOrders: string[]) => setSelectedShipments(selectedOrders)}
                    sortedByColumn={gridSortedBy}
                    sortDirection={gridSortDirection}
                    onSettingsClick={() => console.log('settings')}
                    onRowMoreOptionsClick={(row) => console.log(row)}
                    menuType="shipmentMenu"
                    menuBindedFields={['CargoZoneNumber', 'companyId']}
                    columns={gridColumns}
                    data={shipments}
                    localization={localization.grid}
                    emptyStateText={localization.grid.emptyState}
                    showLoader={showLoader && (activeShipmentsCount <= 1 || !gridColumns.length)}
                    userSettingKey={userSettingKey}
                    lastVisitId={lastVisitShipmentId}
                    idKey="id"
                    onColumnClick={onGridColumnClick}
                    settings={settings}
                    resetFilterFunc={resetFilterFunc}
                />
            </GridWrapper>
            {selectedShipments.length > 0 && (
                <Box
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: '#00375C',
                        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
                        p: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="body1">{selectedShipments.length} Shipments selected</Typography>
                    <div style={{ width: '200px', marginLeft: '20px' }}>
                        <ShipmentStateSelect
                            updateState={bulkUpdateShipments}
                            settingsState={settingsState}
                        ></ShipmentStateSelect>
                    </div>
                </Box>
            )}
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const LoadingOverlay = styled.div`
    background-color: #edf8f9;
    padding: 6px 10px;
    border-radius: 7px;
    position: absolute;
    z-index: 1012;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
`;

const GridWrapper = styled.div`
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: overlay;
    height: 100%;
    width: 100%;
    z-index: 1;

    @media screen and (min-width: 768px) {
        height: calc(100% - 127px);
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - 135px - 40px);
    }

    @media screen and (max-height: 400px) {
        height: calc(100% - 135px);
    }
`;
type CircleProps = {
    color: string;
};

const Circle = styled.div<CircleProps>`
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
    margin: 0 3px;
`;

export default ShipmentsPage;
