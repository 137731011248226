import { ColumnType } from '../../shared/Grid/types/Column';

export const getGridColumns = (regions: string[]): Array<ColumnType<any>> => {
    const columns = [
        {
            title: 'KG',
            field: 'weight',
            sortable: false
        }
    ];
    regions.forEach((region) => {
        columns.push({
            title: region,
            field: region,
            sortable: true
        });
    });
    return columns;
};
