import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { groupBy, round } from 'lodash';
import React from 'react';
import * as XLSX from 'xlsx';
import { CarrierWeightPricing } from '../../../../models/entities/admin/carrierWeightPricing';
import { RegionIdByName } from '../enums/regions.enum';
type Props = {
    updateCarrierWeightPricing: (carrierWeightPricing: CarrierWeightPricing[]) => void;
    selectedCarrierCode: string;
};
const ExcelReader = ({ updateCarrierWeightPricing, }: Props) => {
    const getSheetData = (worksheet: XLSX.WorkSheet, carrierCode: string) => {
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const dataHeader: any = data[0];
        const dataBody = data.slice(1);
        const pricing: CarrierWeightPricing[] = [];
        const jsonData = dataBody.map((row: any) => {
            const obj: {
                weight: string;
                regionPricing: { regionId: number; price: number }[];
            } = { weight: '', regionPricing: [] };
            row.forEach((cell: any, index: any) => {
                const header = dataHeader[index];
                if (header) {
                    const regionId = RegionIdByName[header.trim()];
                    if (regionId) {
                        obj.regionPricing.push({ regionId, price: round(cell, 2) });
                    } else if (header === 'KG') {
                        obj.weight = cell + '';
                    }
                }
            });
            return obj;
        });
        const filteredJsonData = jsonData.filter((row) => parseInt(row.weight) < 101);
        const groupedArr = groupBy(filteredJsonData, 'weight');
        for (const weight of Object.keys(groupedArr)) {
            console.log(weight);
            const row = groupedArr[weight][0];
            row.regionPricing.forEach((regionPricing) => {
                const carrierWeightPricing: CarrierWeightPricing = {
                    carrierCode,
                    region: regionPricing.regionId,
                    weight: +row.weight,
                    price: regionPricing.price
                };
                pricing.push(carrierWeightPricing);
            });
        }
        return pricing;
    };
    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (evt: any) => {
            const data = new Uint8Array(evt.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            // Get the first sheet name and its content
            const firstSheetName = workbook.SheetNames[0];
            const tntExpress = workbook.Sheets[firstSheetName];
            const fourthSheetName = workbook.SheetNames[3];
            const tntEconomy = workbook.Sheets[fourthSheetName];
            const tntExpressData = getSheetData(tntExpress, '1000');
            const tntEconomyData = getSheetData(tntEconomy, '1001');
            updateCarrierWeightPricing([...tntExpressData, ...tntEconomyData]);
        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <Container maxWidth="sm">
            <Box>
                <Button variant="contained" component="label">
                    Upload XLSX File
                    <input type="file" accept="*" hidden onChange={handleFileUpload} />
                </Button>
            </Box>
        </Container>
    );
};

export default ExcelReader;
