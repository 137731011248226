import BaseController from '..';
import { shipment, shipmentOverview } from '../../../models/entities/shipment/shipment';
import { checkOrderConfirmationResponse, confirmOrderPayload } from '../../../models/entities/shipment/public/orderConfirmation';
import { exportTypes, filterFieldsType } from '../../../state/ducks/allShipmentsPage/allShipmentsPage';
import ShipmentEvents from './events/shipmentEvents';
import IShipments from './IShipments';
import ShipmentParties from './parties/shipmentParties';
import ShipmentBillingLines from './accounting/shipmentBillingLines';
import ShipmentAlerts from './alerts/shipmentAlerts';
import ShipmentDiscussions from './discussions/shipmentDiscussions';
import ShipmentDocuments from './documents/shipmentDocuments';
import ShipmentFollow from './follow/shipmentFollow';
import ShipmentHistory from './history/shipmentHistory';
import { downloadFile } from '../../../utils/files';
import ShipmentReferences from './references/shipmentReferences';
import ShipmentItems from './items/shipmentItems';
import ShipmentContainers from './containers/shipmentContainers';
import ShipmentPackages from './packages/shipmentPackages';
import ShipmentFollowUp from './followUp/ShipmentFollowUp';
export default class Shipments extends BaseController implements IShipments {
    private shipmentAlerts: ShipmentAlerts;
    private shipmentParties: ShipmentParties;
    private shipmentEvents: ShipmentEvents;
    private shipmentBillingLines: ShipmentBillingLines;
    private shipmentDiscussions: ShipmentDiscussions;
    private shipmentDocuments: ShipmentDocuments;
    private shipmentFollow: ShipmentFollow;
    private shipmentHistory: ShipmentHistory;
    private shipmentReferences: ShipmentReferences;
    private shipmentItems: ShipmentItems;
    private shipmentContainers: ShipmentContainers;
    private shipmentPackages: ShipmentPackages;
    private shipmentFollowUp: ShipmentFollowUp;
    constructor(baseUrl: string) {
        super(baseUrl);
        this.shipmentAlerts = new ShipmentAlerts(baseUrl);
        this.shipmentParties = new ShipmentParties(baseUrl);
        this.shipmentEvents = new ShipmentEvents(baseUrl);
        this.shipmentBillingLines = new ShipmentBillingLines(baseUrl);
        this.shipmentDiscussions = new ShipmentDiscussions(baseUrl);
        this.shipmentDocuments = new ShipmentDocuments(baseUrl);
        this.shipmentFollow = new ShipmentFollow(baseUrl);
        this.shipmentHistory = new ShipmentHistory(baseUrl);
        this.shipmentReferences = new ShipmentReferences(baseUrl);
        this.shipmentItems = new ShipmentItems(baseUrl);
        this.shipmentContainers = new ShipmentContainers(baseUrl);
        this.shipmentPackages = new ShipmentPackages(baseUrl);
        this.shipmentFollowUp = new ShipmentFollowUp(baseUrl);
    }
    async fetchShipments() {
        const response = await this.client.get('/shipments/allActive');
        return response;
    }
    async fetchMetaShipments() {
        const response = await this.client.get('/shipments/meta');
        return response;
    }
    async fetchShipmentsDashboard() {
        const response = await this.client.get('/shipments/allActive/dashboard');
        return response;
    }
    async fetchById(shipmentNumber: string) {
        const response = await this.client.get(`/shipments/id/${shipmentNumber}`);
        return response;
    }
    async fetchRelatedShipmentsById(shipmentNumber: string) {
        const response = await this.client.get(`/shipments/related/${shipmentNumber}`);
        return response;
    }
    async reStateShipmentById(shipmentId: string) {
        const response = await this.client.put(`/shipments/restate`, JSON.stringify({ shipmentId }));
        return response;
    }
    async fetchByIds(shipmentsIds: string[]) {
        const response = await this.client.post(`/shipments/getByIds`, JSON.stringify({ shipmentsIds }));
        return response;
    }
    async fetchByCZNumber(czNumber: string) {
        const response = await this.client.get(`/shipments/search/${czNumber}`);
        return response;
    }
    async uploadShipmentsByExcel(file: File, companyId?: string, companyName?: string) {
        this.client.setBodyType('FORM-DATA');
        const formData = new FormData();
        formData.append('file', file);
        if (companyId) formData.append('companyId', companyId);
        if (companyName) formData.append('companyName', companyName);
        const response = await this.client.post('/shipments/excel-upload', formData);
        return response;
    }
    async fetchShipmentsByFilter(filter: filterFieldsType) {
        const response = await this.client.post('/shipments/filtered', JSON.stringify({ filters: filter }));
        return response;
    }

    async fetchSendToSapGUID(guid: string) {
        console.log('fetchSendToSapGUID call to API ' + guid);
        const response = await this.client.post('/shipments/sap', JSON.stringify({ shipmentId: guid }));
        return response;
    }
    async createShipment(shipment: shipment) {
        const response = await this.client.post('/shipments', JSON.stringify({ data: shipment }));
        return response;
    }
    async updateShipment(id: string, shipment: shipment): Promise<shipment> {
        const response = await this.client.put('/shipments', JSON.stringify({ shipmentId: id, data: shipment }));
        return response;
    }
    async updateShipmentIsActive(id: string, isActive: boolean) {
        const response = await this.client.put('/shipments/active', JSON.stringify({ shipmentId: id, isActive }));
        return response;
    }
    async updateShipmentOverview(id: string, shipmentOverview: shipmentOverview) {
        const response = await this.client.put('/shipments/overview', JSON.stringify({ shipmentId: id, data: shipmentOverview }));
        return response;
    }
    async updateTransportationPaid(shipmentId: string, transportationPaid: boolean) {
        const response = await this.client.put('/shipments/updateTransportationPaid', JSON.stringify({ shipmentId, transportationPaid }));
        return response;
    }
    async updateShipmentForwarder(id: string, forwarderId: string | null) {
        const response = await this.client.put('/shipments/forwarder', JSON.stringify({ shipmentId: id, forwarderId }));
        return response;
    }
    async shareShipment(emails: string[], content: string, shipmentNumber: string, filesIds: string[], shipmentPdfFile: File) {
        this.client.setBodyType('FORM-DATA');
        const formData = new FormData();
        formData.append('file', shipmentPdfFile);
        formData.append('shipmentId', shipmentNumber);
        formData.append('content', content);
        emails.forEach((email) => formData.append('emails[]', email));
        filesIds.forEach((id) => formData.append('filesIds[]', id));
        const response = await this.client.post(`/shipments/share/${shipmentNumber}`, formData);
        return response;
    }
    async inviteUsersToFollow(users: Array<string>, shipmentId: string, shipmentNumber: string, message: string) {
        const response = await this.client.post('/shipments/inviteToFollow', JSON.stringify({ users, shipmentId, shipmentNumber, message }));
        return response;
    }
    async restoreShipment(shipmentNumber: string) {
        const response = await this.client.put(`/shipments/restore`, JSON.stringify({ entityId: shipmentNumber }));
        return response;
    }
    async deleteShipment(shipmentNumber: string) {
        await this.client.delete(`/shipments/id/${shipmentNumber}`);
    }
    async exportShipment(shipmentNumber: string, type: exportTypes, fileName: string) {
        const response = await this.client.get(`/shipments/${shipmentNumber}/export/${type.toLowerCase()}`, true);
        const blob = await response.blob();
        downloadFile(blob, fileName);
    }
    async fetchShipmentPackages(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/packages/${shipmentNumber}`);
        return response;
    }
    async fetchShipmentReferences(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/references/${shipmentNumber}`);
        return response;
    }
    async fetchShipmentRoutes(shipmentNumber: string) {
        const response = await this.client.get(`/shipment/routes/${shipmentNumber}`);
        return response;
    }
    async updateShipmentState(shipmentId: string, state: string, forwarderId?: string) {
        const response = await this.client.put(`/shipments/state`, JSON.stringify({ shipmentId, data: { state, forwarderId } }));
        return response;
    }

    async updateShipmentAccountingState(shipmentId: string, accountingState: string) {
        const response = await this.client.put(`/shipment/accounting/accountingState`, JSON.stringify({ shipmentId, data: { accountingState } }));
        return response;
    }
    async dismissRequiredDoc(shipmentId: string, docType: string) {
        const response = await this.client.post(`/shipment/dismissedDocs`, JSON.stringify({ shipmentId, docType }));
        return response;
    }
    //#region Public order confirmation
    async checkOrderConfirmed(token: string): Promise<checkOrderConfirmationResponse> {
        this.client.headers.Authorization = 'Bearer ' + token;
        const response = await this.client.get(`/public/orderConfirmation`);
        return response;
    }
    async confirmOrder(payload: confirmOrderPayload): Promise<void> {
        const response = await this.client.post(`/public/orderConfirmation`, JSON.stringify(payload));
        return response;
    }
    bulkUpdateShipmentState(shipmentIds: string[], state: string) {
        const response = this.client.put(`/shipments/bulkUpdateState`, JSON.stringify({ shipmentIds, state }));
        return response;
    }
    //#endregion
    alerts() {
        return this.shipmentAlerts;
    }
    parties() {
        return this.shipmentParties;
    }
    references() {
        return this.shipmentReferences;
    }
    events() {
        return this.shipmentEvents;
    }
    billingLines() {
        return this.shipmentBillingLines;
    }
    discussions() {
        return this.shipmentDiscussions;
    }
    documents() {
        return this.shipmentDocuments;
    }
    follow() {
        return this.shipmentFollow;
    }
    history() {
        return this.shipmentHistory;
    }
    items() {
        return this.shipmentItems;
    }
    containers() {
        return this.shipmentContainers;
    }
    packages() {
        return this.shipmentPackages;
    }
    followUp() {
        return this.shipmentFollowUp;
    }
}
