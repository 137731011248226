import Auth from './controllers/auth/auth';
import AuthMock from './controllers/auth/authMock';
import BusinessPartners from './controllers/businessPartners/businessPartners';
import Shipments from './controllers/shipments/shipments';
import User from './controllers/user/user';
import Admin from './controllers/admin/admin';
import Files from './controllers/files/files';
import Task from './controllers/task/task';
import Dashboard from './controllers/dashboard/dashboard';
import UserRegistration from './controllers/userRegistration/userRegistration';
import Notification from './controllers/notification/notification';
import PurchaseOrders from './controllers/purchaseOrders/purchaseOrders';
import PurchaseDemands from './controllers/purchaseDemands/purchaseDemands';
import Bids from './controllers/bids/bids';
import Chat from './controllers/chat/chat';
import Containers from './controllers/containers/containers';
import Products from './controllers/products/products';
import Companies from './controllers/companies/companies';
import Categories from './controllers/categories/categories';
import InventoryLogs from './controllers/inventory/inventoryLogs';
import Tariffs from './controllers/tariffs/tariff';
import Orders from './controllers/orders/order';
import CarrierWeightPricingController from './controllers/carrierWeightPricing/carrierWeightPricing';
import ShipmentFollowUp from './controllers/shipments/followUp/ShipmentFollowUp';

class CargoZoneSDK {
    private baseUrl: string;
    private isMock: boolean;

    constructor(baseUrl: string = '', isMock: boolean = false) {
        this.baseUrl = baseUrl;
        this.isMock = isMock;
    }
    auth() {
        // if (this.isMock) return new AuthMock();
        return new Auth(this.baseUrl);
    }
    user() {
        return new User(this.baseUrl);
    }
    businessPartners() {
        return new BusinessPartners(this.baseUrl);
    }
    shipments() {
        return new Shipments(this.baseUrl);
    }
    purchaseOrders() {
        return new PurchaseOrders(this.baseUrl);
    }
    purchaseDemands() {
        return new PurchaseDemands(this.baseUrl);
    }
    files() {
        return new Files(this.baseUrl);
    }
    task() {
        return new Task(this.baseUrl);
    }
    dashboard() {
        return new Dashboard(this.baseUrl);
    }
    userRegistration() {
        return new UserRegistration(this.baseUrl);
    }
    containers() {
        return new Containers(this.baseUrl);
    }
    notification() {
        return new Notification(this.baseUrl);
    }
    bids() {
        return new Bids(this.baseUrl);
    }
    chat() {
        return new Chat(this.baseUrl);
    }
    admin() {
        return new Admin(this.baseUrl);
    }
    products() {
        return new Products(this.baseUrl);
    }
    inventory() {
        return new InventoryLogs(this.baseUrl);
    }
    companies() {
        return new Companies(this.baseUrl);
    }
    categories() {
        return new Categories(this.baseUrl);
    }
    orders() {
        return new Orders(this.baseUrl);
    }
    tariffs() {
        return new Tariffs(this.baseUrl);
    }
    carrierWeightPricing() {
        return new CarrierWeightPricingController(this.baseUrl);
    }
    followUp() {
        return new ShipmentFollowUp(this.baseUrl);
    }
}

export const localSDK = new CargoZoneSDK(`${process.env.REACT_APP_API_URL}/api`);
