import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { FollowUp as FollowUpModel } from '../../../models/entities/shipment/shipment';
import { userSelectors } from '../../../state/ducks/user/selectors';
import { userDetails } from '../../../state/ducks/users/users';
import { RootState } from '../../../state/store/store';
import FollowUp, { IFollowUpExtended } from './FollowUp';
import { MetaShipment } from '../../../state/ducks/shipments/shipments';

type PathParams = {};

type Props = RouteComponentProps<PathParams> & {
    followUps: IFollowUpExtended[];
    metaShipments: MetaShipment[];
    getFollowUps: () => void;
    getMetaShipments: () => void;
    addNewFollowUp: (shipmentId: string, followUp: FollowUpModel) => Promise<void>;
    updateFollowUp: (shipmentId: string, followUp: FollowUpModel) => Promise<void>;
    updateFollowUpCompletionStatus: (shipmentId: string, followUpId: number, isCompleted: boolean) => Promise<void>;
    deleteFollowUp: (shipmentId: string, followUpId: number) => Promise<void>;
    users: userDetails[];
    showAllFollowUps: boolean;
    setShowAllFollowUps: (showAllFollowUps: boolean) => void;
    currentUserId: string;
    setLastVisitShipmentId: (shipmentId: string) => void;
    lastVisitShipmentId?: string;
};

const FollowUpConnected = ({
    metaShipments,
    getMetaShipments,
    getFollowUps,
    followUps,
    addNewFollowUp,
    updateFollowUpCompletionStatus,
    deleteFollowUp,
    updateFollowUp,
    users,
    showAllFollowUps,
    setShowAllFollowUps,
    currentUserId,
    setLastVisitShipmentId,
    lastVisitShipmentId
}: Props) => {
    useEffect(() => {
        getFollowUps();
        getMetaShipments();
    }, []);
    return (
        <FollowUp
            metaShipments={metaShipments}
            followUps={followUps}
            addNewFollowUp={addNewFollowUp}
            updateFollowUpCompletionStatus={updateFollowUpCompletionStatus}
            deleteFollowUp={deleteFollowUp}
            users={users}
            updateFollowUp={updateFollowUp}
            showAllFollowUps={showAllFollowUps}
            setShowAllFollowUps={setShowAllFollowUps}
            currentUserId={currentUserId}
            setLastVisitShipmentId={setLastVisitShipmentId}
            lastVisitShipmentId={lastVisitShipmentId}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    followUps: state.followUps.followUps,
    showAllFollowUps: state.followUps.showAllFollowUps,
    metaShipments: state.shipments.metaShipments,
    users: userSelectors.usersInSameNetworkIncludeSelf(state),
    currentUserId: state.user.userInfo.id,
    lastVisitShipmentId: state.followUps.lastVisitShipmentId
});

const mapDispatchToProps = (dispatch: any) => ({
    getFollowUps: () => dispatch.followUps.getFollowUps(),
    getMetaShipments: () => dispatch.shipments.fetchMetaShipments(),
    updateFollowUp: (shipmentId: string, followUp: FollowUpModel) => dispatch.followUps.updateFollowUp({ shipmentId, followUp }),
    addNewFollowUp: (shipmentId: string, followUp: FollowUpModel) => dispatch.followUps.addNewFollowUp({ shipmentId, followUp }),
    updateFollowUpCompletionStatus: (shipmentId: string, followUpId: number, isCompleted: boolean) =>
        dispatch.followUps.updateFollowUpCompletionStatus({ shipmentId, followUpId, isCompleted }),
    deleteFollowUp: (shipmentId: string, followUpId: number) => dispatch.followUps.deleteFollowUp({ shipmentId, followUpId }),
    setShowAllFollowUps: (showAllFollowUps: boolean) => dispatch.followUps.setShowAllFollowUps(showAllFollowUps),
    setLastVisitShipmentId: (shipmentId: string) => dispatch.followUps.setLastVisitShipmentId(shipmentId)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(FollowUpConnected));
