import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FollowUp, shipment } from '../../../../models/entities/shipment/shipment';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { userDetails } from '../../../../state/ducks/users/users';
import { RootState } from '../../../../state/store/store';
import warningGreen from '../../../../static/icons/warning-green-18dp.svg';
import warningOrange from '../../../../static/icons/warning-orange-18dp.svg';
import warningRed from '../../../../static/icons/warning-red-18dp.svg';
import warningBlack from '../../../../static/icons/warning-black-18dp.svg';
import FollowUpTab from '../../../pages/singleShipment/sections/FollowUpTab';
import Modal from '../../Modal/Modal';
import { makeStyles, Popover } from '@material-ui/core';
type Props = {
    shipment: shipment;
    addNewFollowUp: (shipmentId: string, followUp: FollowUp) => Promise<void>;
    updateFollowUpCompletionStatus: (shipmentId: string, followUpId: number, isCompleted: boolean) => Promise<void>;
    deleteFollowUp: (shipmentId: string, followUpId: number) => Promise<void>;
    users: userDetails[];
    currentUserId: string;
};
interface ImageProps {
    width?: number;
}
const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    popoverContent: {
        pointerEvents: 'auto',
        minWidth: '90px',
        minHeight: '90px',
        display: 'flex',
        alignItems: 'center'
    }
}));
const FollowUpRenderer = ({ shipment, addNewFollowUp, deleteFollowUp, updateFollowUpCompletionStatus, users, currentUserId }: Props) => {
    const dueDates = shipment.followUps ? shipment.followUps.map((followUp) => moment(followUp.dueDate)) : [];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);
    const classes = useStyles();

    let minDueDateStr = '';
    let src = warningGreen;
    if (dueDates.length) {
        const minDueDate = moment.min(dueDates);
        minDueDateStr = minDueDate.format('DD/MM/YYYY');
        const currentDate = moment();
        const diff = minDueDate.diff(currentDate, 'days');
        if (diff < 0) {
            src = warningBlack;
        } else if (diff < 1) {
            src = warningRed;
        } else if (diff < 2) {
            src = warningOrange;
        } else {
            src = warningGreen;
        }
    }
    const popoverEnter = async ({ currentTarget }: any) => {
        setOpenedPopover(true);
    };
    const popoverLeave = ({ currentTarget }: any) => {
        setOpenedPopover(false);
    };
    const onClick = useCallback(
        (e) => {
            if (!isModalOpen) {
                setIsModalOpen(true);
                console.log('isModalOpen', isModalOpen);
            }
        },
        [setIsModalOpen, isModalOpen]
    );
    const onCancelCallback = useCallback(() => {
        setIsModalOpen(false);
        console.log('isModalOpen', isModalOpen);
    }, [setIsModalOpen, isModalOpen]);
    return (
        <>
            <Container key={shipment.id + 'container'} onClick={onClick}>
                <Modal
                    title={{ text: 'Add Follow up Task' }}
                    width="1200px"
                    isOpen={isModalOpen}
                    onCancelCallback={onCancelCallback}
                    key={shipment.id + 'followup'}
                    Content={() => {
                        return (
                            <FollowUpTab
                                shipment={shipment}
                                addNewFollowUp={addNewFollowUp}
                                deleteFollowUp={deleteFollowUp}
                                updateFollowUpCompletionStatus={updateFollowUpCompletionStatus}
                                users={users}
                                currentUserId={currentUserId}
                            />
                        );
                    }}
                ></Modal>
                <PopContainer
                    ref={popoverAnchor}
                    aria-owns="mouse-over-popover"
                    aria-haspopup="true"
                    onMouseEnter={popoverEnter}
                    onMouseLeave={popoverLeave}
                >
                    <Image src={src} alt={minDueDateStr} width={20} />
                </PopContainer>
            </Container>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent
                }}
                PaperProps={{
                    onMouseEnter: popoverEnter,
                    onMouseLeave: popoverLeave
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: -14
                }}
            >
                <PopoverContent>
                    {dueDates.length ? (
                        <div>
                            <div>Due Date: {minDueDateStr}</div>
                            <div>Follow Ups: {dueDates.length}</div>
                        </div>
                    ) : (
                        'No follow ups'
                    )}
                </PopoverContent>
            </Popover>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    users: userSelectors.usersInSameNetworkIncludeSelf(state),
    currentUserId: state.user.userInfo.id
});

const mapDispatchToProps = (dispatch: any) => ({
    addNewFollowUp: (shipmentId: string, followUp: FollowUp) => dispatch.shipments.addNewFollowUp({ shipmentId, followUp }),
    updateFollowUpCompletionStatus: (shipmentId: string, followUpId: number, isCompleted: boolean) =>
        dispatch.shipments.updateFollowUpCompletionStatus({ shipmentId, followUpId, isCompleted }),
    deleteFollowUp: (shipmentId: string, followUpId: number) => dispatch.shipments.deleteFollowUp({ shipmentId, followUpId })
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpRenderer);

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
`;
const PopContainer = styled.div``;
const PopoverContent = styled.div`
    flex: 1;
    padding: 0 25px;
    display: flex;
    flex-direction: column;

    & :last-child {
        margin-bottom: 0;
    }
`;

const Image = styled.img<ImageProps>`
    ${(props) => (props.width ? 'width:' + props.width + 'px' : 'width:20px')};
`;
