import React, { useEffect, useState } from 'react';
import { styled } from '../../../../shared/Theme/theme';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { FormCheckbox, FormDatePicker, FormDropDown, FormNumberInput, FormTextArea } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { billingLine } from '../../../../../models/entities/common_subentities/billingLine';
import currencyList from '../../../../shared/inputs/form/CurrencyInput/currencyList';
import getSymbolFromCurrency from 'currency-symbol-map';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

export enum formMode {
    NEW,
    EDIT
}

interface IBillingLineSettings {
    billingLineServiceType?: { code: string; name: string; description: string }[];
}

interface formFields {
    isRelativeCostEnabled?: boolean;
    serviceType?: string;
    currency?: string;
    income?: number;
    expense?: number;
    totalCost?: number;
    relativeCost?: number;
    remarks?: string;
    profit?: number;
    date?: Date;
    chargeBy?: string;
    sellingRate?: number;
}

type Props<entityType, settingsType> = {
    entity?: entityType;
    billingLine?: billingLine;
    mode: formMode;
    localization: any;
    settings?: settingsType;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createBillingLine: (entityId: string, billingLine: billingLine) => void;
    updateBillingLine: (entityId: string, billingLine: billingLine) => void;
    showLoader?: boolean;
    UserCompanyID?: string;
    weight?: number;
    volume?: number;
};

let AFTER_LOADING: boolean = false;

function AccountingFormConnected<entityType extends { id: string }, settingsType extends IBillingLineSettings>({
    entity,
    billingLine,
    localization,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createBillingLine,
    updateBillingLine,
    showLoader,
    UserCompanyID,
    weight,
    volume
}: Props<entityType, settingsType>) {
    const [submitLoading, setLoading] = useState(false);

    const billingLineServiceTypeOptions = settings?.billingLineServiceType?.map((item) => ({
        text: item.name,
        value: item.code
    }));

    const currencyOptions = currencyList.data.map((c) => ({
        text: getSymbolFromCurrency(c.Ccy) || c.Ccy || c.CcyNm,
        value: c.Ccy || c.CcyNm
    }));

    const initFormValues: formFields = {
        isRelativeCostEnabled: false,
        serviceType: '',
        currency: '',
        income: undefined,
        expense: undefined,
        totalCost: undefined,
        relativeCost: undefined,
        date: undefined,
        remarks: '',
        profit: undefined,
        chargeBy: 'fixed',
        sellingRate: undefined
    };
    const setInitialValues = () => {
        if (!billingLine || mode === formMode.NEW) {
            return initFormValues;
        } else {
            initFormValues.isRelativeCostEnabled = billingLine.relativeCost < 100;
            initFormValues.serviceType = billingLine.serviceType;
            initFormValues.currency = billingLine.currency;
            initFormValues.income = billingLine.income;
            initFormValues.expense = billingLine.expense;
            initFormValues.totalCost = billingLine.totalCost;
            initFormValues.relativeCost = billingLine.relativeCost;
            initFormValues.date = billingLine.date || new Date();
            initFormValues.remarks = billingLine.remarks;
            initFormValues.profit = billingLine.profit;
            initFormValues.chargeBy = billingLine.chargeBy ?? 'fixed';
            initFormValues.sellingRate = billingLine.sellingRate ?? 0;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }

        const errors: formFields = {};
        if (!values.serviceType) errors.serviceType = localization.billing_lines.form.required_error;
        if (!values.currency) errors.currency = localization.billing_lines.form.required_error;
        if (values.relativeCost > 100) errors.relativeCost = localization.billing_lines.form.relative_cost_error;
        if (values.remarks.length > 1000) errors.remarks = localization.billing_lines.form.remarks_max_length_error;
        return errors;
    };

    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING) {
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);
    const getAmount = (rate: number, chargeBy: string) => {
        if (chargeBy === 'weight') {
            return rate * (weight || 0);
        } else if (chargeBy === 'volume') {
            return rate * (volume || 0);
        } else {
            return rate;
        }
    };
    return (
        <Formik
            initialValues={setInitialValues()}
            validate={validate}
            onSubmit={(values, { resetForm, setSubmitting }) => {
                if (!entity) {
                    console.error('Entity is undefined. Cannot submit the form.');
                    return;
                }
                const payload: billingLine = {
                    serviceType: values.serviceType,
                    currency: values.currency,
                    income: Number(values.income) || 0,
                    expense: Number(values.expense) || 0,
                    totalCost:
                        (!values.isRelativeCostEnabled ? Number(values.expense) : Number(values.expense || 0 / (values.relativeCost || 0 / 100))) ||
                        0,
                    relativeCost: (!initFormValues.isRelativeCostEnabled ? Number(values.relativeCost) : 100) || 100,
                    remarks: values.remarks,
                    date: values.date || new Date(),
                    profit: Number(values.income || 0) - Number(values.expense || 0),
                    displayOnlyForCompanyId: UserCompanyID,
                    chargeBy: values.chargeBy ?? 'fixed',
                    sellingRate: values.sellingRate ?? 0
                } as billingLine;

                if (mode === formMode.NEW) {
                    createBillingLine(entity.id, { ...payload });
                } else if (billingLine) {
                    updateBillingLine(entity.id, {
                        ...payload,
                        id: billingLine.id
                    });
                }

                if (openedFromModal) {
                    openedFromModal.setVisible(false);
                }
                setSubmitting(false);
            }}
        >
            {({ values, errors, validateForm, resetForm, setSubmitting, touched, setTouched, setFieldValue }) => {
                const submit = (values: any, nextBillingLine = false) => {
                    if (!entity) {
                        console.error('Entity is undefined. Cannot submit the form.');
                        return;
                    }
                    const chargeBy = values.chargeBy ?? 'fixed';
                    const income = getAmount(Number(values.sellingRate || 0), chargeBy);
                    const expense = Number(values.expense || 0);
                    const payload: billingLine = {
                        serviceType: values.serviceType,
                        currency: values.currency,
                        income,
                        expense ,
                        totalCost:
                            (!values.isRelativeCostEnabled ? Number(values.expense) : Number(values.expense / (values.relativeCost / 100))) || 0,
                        relativeCost: (!initFormValues.isRelativeCostEnabled ? Number(values.relativeCost) : 100) || 100,
                        remarks: values.remarks,
                        date: values.date || new Date(),
                        profit: Number(values.income || 0) - expense,
                        displayOnlyForCompanyId: UserCompanyID,
                        chargeBy,
                        sellingRate: values.sellingRate ?? 0
                    } as billingLine;

                    setTouched({
                        serviceType: true,
                        currency: true,
                        income: true,
                        expense: true,
                        totalCost: true,
                        relativeCost: true,
                        remarks: true,
                        date: true
                    });

                    validateForm().then((errors) => {
                        if (Object.keys(errors).length === 0) {
                            if (mode === formMode.NEW) {
                                createBillingLine(entity.id, { ...payload });
                            } else if (billingLine) {
                                updateBillingLine(entity.id, {
                                    ...payload,
                                    id: billingLine.id
                                });
                            }

                            if (nextBillingLine && mode === formMode.NEW) {
                                resetForm();
                                setSubmitting(false);
                            } else if (openedFromModal) {
                                openedFromModal.setVisible(false);
                            }
                        } else {
                            console.log('Form validation failed', errors);
                        }
                    });
                };
                const updateIncome = (sellingRate: number, chargeBy: string) => {
                    const income = getAmount(sellingRate, chargeBy);
                    setFieldValue('sellingRate', sellingRate); // update sellingRate if needed
                    setFieldValue('income', Number(income)); // update income properly
                    setFieldValue('chargeBy', chargeBy);
                };
                return (
                    <Form>
                        {submitLoading && <Loader width="20px" marginTop="0px" showText={false} />}
                        <Container>
                            <FieldLabel>{localization.billing_lines.form.billinglineservicetype}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    name="serviceType"
                                    placeHolder={localization.billing_lines.form.billinglineservicetype_placeholder}
                                    options={billingLineServiceTypeOptions || []}
                                    allowClear={true}
                                    showSearch={true}
                                />
                            </FieldContainer>
                            <FieldLabel>{localization.billing_lines.form.billinglinecurrency}</FieldLabel>
                            <FieldContainer>
                                <FormDropDown
                                    name="currency"
                                    placeHolder={localization.billing_lines.form.billinglinecurrency_placeholder}
                                    options={currencyOptions || []}
                                    allowClear={true}
                                    showSearch={true}
                                    //style={{ width: '150px', marginRight: '15px' }}
                                />
                            </FieldContainer>
                            <FlexRow>
                                <FlexItem>
                                    <FieldLabel>{localization.billing_lines.form.billinglineincome}</FieldLabel>
                                </FlexItem>
                                <FlexItem>
                                    <FieldLabel>{localization.billing_lines.form.billinglineexpense}</FieldLabel>
                                </FlexItem>
                            </FlexRow>
                            <FlexRow>
                                <FieldContainer>
                                    <FormNumberInput
                                        name="expense"
                                        placeHolder={localization.billing_lines.form.billinglineincome_placeholder}
                                        style={{ width: '150px', marginRight: '15px' }}
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <FormNumberInput
                                        name="sellingRate"
                                        placeHolder="Selling Rate"
                                        style={{ width: '150px', marginRight: '15px' }}
                                        onChange={(e: any) => {
                                            const chargeBy = values.chargeBy ?? 'fixed';
                                            const sellingRate = Number(e ?? 0);
                                            updateIncome(sellingRate, chargeBy);
                                        }}
                                    />
                                </FieldContainer>
                            </FlexRow>
                            <FlexRow>
                                <FieldContainer>
                                    <FieldLabel>
                                        <FormCheckbox name="isRelativeCostEnabled" style={{ color: '#008ac9' }}>
                                            {'Relative Cost?'}
                                        </FormCheckbox>
                                    </FieldLabel>
                                    {values.isRelativeCostEnabled && (
                                        <>
                                            <FieldLabel>{localization.billing_lines.form.billinglinerelativecost}</FieldLabel>

                                            <FormNumberInput
                                                name="relativeCost"
                                                placeHolder={localization.billing_lines.form.billinglinerelativecost_placeholder}
                                                style={{ width: '150px', marginRight: '15px' }}
                                            />
                                        </>
                                    )}
                                </FieldContainer>
                                <FieldContainer>
                                    <FieldLabel>Income</FieldLabel>
                                    <FormNumberInput name="income" placeHolder="Income" style={{ width: '150px', marginRight: '15px' }} disabled />
                                </FieldContainer>
                            </FlexRow>
                            <FieldContainer>
                                <FieldLabel>Charge By</FieldLabel>
                                <Field name="chargeBy">
                                    {({ field }: any) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            style={{ marginLeft: '10px' }}
                                            onChange={(e) => updateIncome(values.sellingRate ?? 0, e.target.value)}
                                        >
                                            <FormControlLabel value="weight" control={<Radio color="default" />} label="Weight" />
                                            <FormControlLabel value="volume" control={<Radio color="default" />} label="CBM/Volume" />
                                            <FormControlLabel value="fixed" control={<Radio color="default" />} label="Fixed" />
                                        </RadioGroup>
                                    )}
                                </Field>
                            </FieldContainer>
                            <FieldLabel>{localization.billing_lines.form.billinglineremarks}</FieldLabel>
                            <FieldContainer>
                                <FormTextArea name="remarks" placeHolder={localization.billing_lines.form.billinglineremarks_placeholder} />
                            </FieldContainer>
                            <FieldLabel>{localization.billing_lines.form.billinglinedate}</FieldLabel>
                            <FieldContainer>
                                <FormDatePicker name="date" placeHolder={localization.billing_lines.form.billinglinedate_placeholder} />
                            </FieldContainer>
                        </Container>
                        {openedFromModal && (
                            <FormFooter>
                                {mode === formMode.NEW && (
                                    <Button minWidth="55px" buttonType="filled" type="button" onClick={() => submit(values, true)}>
                                        <span>{localization.billing_lines.modal.next_billing_line_btn}</span>
                                    </Button>
                                )}
                                {mode === formMode.EDIT && (
                                    <Button minWidth="55px" buttonType="filled" type="submit">
                                        <span>{localization.billing_lines.modal.edit_btn}</span>
                                    </Button>
                                )}
                                <Button minWidth="55px" onClick={() => openedFromModal.setVisible(false)}>
                                    <span>
                                        {mode === formMode.NEW
                                            ? localization.billing_lines.modal.done_btn
                                            : localization.billing_lines.modal.cancel_btn}
                                    </span>
                                </Button>
                            </FormFooter>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}

const FieldContainer = styled.div`
    margin-bottom: 12px;
    flex: 1;
`;
const FlexItem = styled.div`
    flex: 1;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
`;
const FlexRow = styled.div`
    display: flex;
`;

export default AccountingFormConnected;
