import BaseController from '../..';
import { IFollowUpExtended } from '../../../../components/pages/Followup/FollowUp';
import { FollowUp } from '../../../../models/entities/shipment/shipment';
import IShipmentFollowUp from './IShipmentFollowUp';

export default class ShipmentFollowUp extends BaseController implements IShipmentFollowUp {
    constructor(baseUrl: string) {
        super(baseUrl);
    }
    async getFollowUps(showAllFollowUps: boolean): Promise<IFollowUpExtended[]> {
        const response = await this.client.get('/followUps?showAllFollowUps=' + showAllFollowUps);
        return response;
    }

    async addNewFollowUp(shipmentId: string, followUp: Partial<FollowUp>): Promise<FollowUp> {
        const response = await this.client.post(`/shipments/${shipmentId}/followUp`, JSON.stringify({ ...followUp }));
        return response;
    }

    async updateFollowUpCompletionStatus(shipmentId: string, id: number, isCompleted: boolean): Promise<FollowUp> {
        const response = await this.client.put(`/shipments/${shipmentId}/followUp/${id}/completion`, JSON.stringify({ isCompleted }));
        return response;
    }

    async deleteFollowUp(shipmentId: string, id: number): Promise<{ success: boolean }> {
        const response = await this.client.delete(`/shipments/${shipmentId}/followUp/${id}`);
        return response;
    }
    async updateFollowUp(shipmentId: string, followUp: Partial<FollowUp>): Promise<FollowUp> {
        const response = await this.client.put(`/followUps/${followUp.id}`, JSON.stringify({ ...followUp }));
        return response;
    }
}
