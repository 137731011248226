import { Field, FieldProps, Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { FollowUp } from '../../../models/entities/shipment/shipment';
import { MetaShipment } from '../../../state/ducks/shipments/shipments';
import { userDetails } from '../../../state/ducks/users/users';
import FormFieldLabel from '../../shared/Form/FormFieldLabel';
import { Checkbox, TextArea } from '../../shared/inputs/base';
import { UserDropDownForm } from '../../shared/inputs/form';
import Modal from '../../shared/Modal/Modal';
import Button from '../../shared/SmallComponents/Button';
import CZDatePicker from '../../shared/SmallComponents/DatePicker';
import ShipmentDropDownBase from '../../shared/inputs/base/ShipmentDropDown';
import moment from 'moment';

interface FollowUpTaskModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: any) => Promise<void>;
    initialValues?: {
        id?: number;
        todo: string;
        dueDate: Date;
        assignedTo: string;
        shipmentId: string;
        isCompleted?: boolean;
    };
    users: userDetails[];
    metaShipments: MetaShipment[];
    isEdit?: boolean;
    currentUserId: string;
}

const FollowUpTaskModal: React.FC<FollowUpTaskModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    initialValues,
    users,
    metaShipments,
    isEdit = false,
    currentUserId
}) => {
    const defaultValues = {
        todo: '',
        dueDate: moment().toDate(),
        assignedTo: currentUserId,
        shipmentId: '',
        isCompleted: false
    };

    const validate = (values: any) => {
        const errors: any = {};
        if (!values.todo) {
            errors.todo = 'Task is required';
        }
        if (!values.dueDate) {
            errors.dueDate = 'Due Date is required';
        }
        if (!values.assignedTo) {
            errors.assignedTo = 'Assignee is required';
        }
        if (!values.shipmentId) {
            errors.shipmentId = 'Shipment is required';
        }
        return errors;
    };

    const handleSubmit = async (values: any) => {
        values.dueDate = moment(values.dueDate).format('YYYY-MM-DD HH:mm:ss Z');
        await onSubmit(values);
        onClose();
    };

    return (
        <Modal
            title={{ text: isEdit ? 'Edit Follow-up Task' : 'Add Follow-up Task' }}
            width="600px"
            isOpen={isOpen}
            onCancelCallback={onClose}
            Content={() => {
                return (
                    <ModalContent>
                        <Formik initialValues={initialValues || defaultValues} validate={validate} onSubmit={handleSubmit}>
                            {() => {
                                return (
                                    <Form>
                                        <FormGroup>
                                            <FormFieldLabel text="Select Shipment" />
                                            <Field name="shipmentId">
                                                {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                                                    <ShipmentDropDownBase
                                                        metaShipments={metaShipments}
                                                        value={value}
                                                        showSearch
                                                        placeHolder="Select Shipment"
                                                        onChange={(e: any) => {
                                                            setFieldValue('shipmentId', e);
                                                        }}
                                                        // disabled={isEdit}
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormFieldLabel text="Task" />
                                            <Field name="todo">
                                                {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                                                    <TextArea
                                                        name="todo"
                                                        value={value}
                                                        autoSize={{ minRows: 2 }}
                                                        width="300px"
                                                        onChange={(e: any) => {
                                                            setFieldValue('todo', e);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>

                                        <FormGroup>
                                            <FormFieldLabel text="Due Date" />
                                            <Field name="dueDate">
                                                {({ field, meta }: FieldProps) => (
                                                    <CZDatePicker
                                                        name="dueDate"
                                                        style={{ width: '300px' }}
                                                        placeHolder="Select Due Date"
                                                        value={field.value}
                                                        error={meta.error}
                                                        touched={meta.touched}
                                                        showTime={true}
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>

                                        <FormGroup>
                                            <FormFieldLabel text="Assign To" />
                                            <UserDropDownForm
                                                name="assignedTo"
                                                placeHolder={'Select Assignee'}
                                                users={users}
                                                customUsers
                                                showSearch
                                            />
                                        </FormGroup>

                                        {isEdit && (
                                            <FormGroup>
                                                <FormFieldLabel text="Completed" />
                                                <Field name="isCompleted">
                                                    {({ field }: FieldProps) => (
                                                        <Checkbox
                                                            checked={field.value}
                                                            onChange={(e) =>
                                                                field.onChange({
                                                                    target: {
                                                                        name: 'isCompleted',
                                                                        value: e.target.checked
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        )}

                                        <ButtonContainer>
                                            <Button onClick={onClose}>Cancel</Button>
                                            <Button type="submit">Save</Button>
                                        </ButtonContainer>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </ModalContent>
                );
            }}
        />
    );
};

export default FollowUpTaskModal;

const FormGroup = styled.div`
    margin-bottom: 15px;
    width: 100%;
`;

const ModalContent = styled.div`
    padding: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;
