export const RegionIdByName: { [key: string]: number } = {
    'Europe': 1,
    'China & HKG': 2,
    'Singapore, Japan': 3,
    'India': 4,
    'Vietnam': 5,
    'Thailand': 6,
    'Far East Others': 7,
    'Australia, N. Zealand, Bahrain, Sth Africa & Latin Am.': 8,
    'ROW': 9,
    'Western Europe': 10
};

// Inverse mapping to get the region name from its number.
export const RegionNameById: { [key: number]: string } = {
    1: 'Europe',
    2: 'China & HKG',
    3: 'Singapore, Japan',
    4: 'India',
    5: 'Vietnam',
    6: 'Thailand',
    7: 'Far East Others',
    8: 'Australia, N. Zealand, Bahrain, Sth Africa & Latin Am.',
    9: 'ROW',
    10: 'Western Europe'
};