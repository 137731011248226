import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CarrierWeightPricing as CarrierWeightPricingModel } from '../../../models/entities/admin/carrierWeightPricing';
import selectors from '../../../state/ducks/carrierWeightPricing/selectors';
import { RootState } from '../../../state/store/store';
import CarrierWeightPricing from './CarrierWeightPricing';

type PathParams = {};

type Props = RouteComponentProps<PathParams> & {
    carrierWeightPricing: CarrierWeightPricingModel[];
    selectedCarrierCode: string;
    setSelectedCarrierCode: (carrierCode: string) => void;
    fetchCarrierWeightPricing: (carrierCode: string) => void;
    updateCarrierWeightPricing: (carrierWeightPricing: CarrierWeightPricingModel[]) => void;
    regionsInData: string[];
};

const CarrierWeightPricingConnected = ({
    carrierWeightPricing,
    selectedCarrierCode,
    setSelectedCarrierCode,
    fetchCarrierWeightPricing,
    updateCarrierWeightPricing,
    regionsInData
}: Props) => {
    useEffect(() => {
        if (selectedCarrierCode) {
            fetchCarrierWeightPricing(selectedCarrierCode);
        }
    }, [selectedCarrierCode]);
    return (
        <CarrierWeightPricing
            carrierWeightPricing={carrierWeightPricing}
            selectedCarrierCode={selectedCarrierCode}
            setSelectedCarrierCode={setSelectedCarrierCode}
            updateCarrierWeightPricing={updateCarrierWeightPricing}
            regionsInData={regionsInData}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    carrierWeightPricing: selectors.getRegionTransformerPricing(state),
    selectedCarrierCode: selectors.selectedCarrierCode(state),
    regionsInData: selectors.regionsInData(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchCarrierWeightPricing: (carrierCode: string) => dispatch.carrierWeightPricing.fetchCarrierWeightPricing(carrierCode),
    updateCarrierWeightPricing: (carrierWeightPricing: CarrierWeightPricingModel[]) =>
        dispatch.carrierWeightPricing.updateCarrierWeightPricing({carrierWeightPricing}),
    setSelectedCarrierCode: (carrierCode: string) => dispatch.carrierWeightPricing.setSelectedCarrierCode(carrierCode)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps as any)(CarrierWeightPricingConnected));
