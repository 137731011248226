import React from 'react';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';
import claimsType from '../../../../../models/entities/claimsType';
import { event } from '../../../../../models/entities/common_subentities/event';
import { FollowUp, settings, shipment } from '../../../../../models/entities/shipment/shipment';
import Events from '../../../commonSubEntities/events/Events';
import EventFormConnected from '../../EventForm/EventFormConnected';
import { userDetails } from '../../../../../state/ducks/users/users';

type Props = {
    events?: Array<event>;
    localization: any;
    settings?: settings;
    printMode?: boolean;
    userShipmentEventsClaims?: claimsType;
    featureFlags?: featureFlagsModel;
    users: userDetails[];
    shipment: shipment;
    addNewFollowUp: (shipmentId: string, followUp: FollowUp) => Promise<void>;
};

function ShipmentEvents({
    events,
    localization,
    settings,
    printMode,
    userShipmentEventsClaims,
    featureFlags,
    users,
    shipment,
    addNewFollowUp
}: Props) {
    return (
        <Events<settings>
            events={events}
            localization={localization}
            settings={settings}
            menuType="shipmentEventMenu"
            EventFormConnected={EventFormConnected}
            printMode={printMode}
            userShipmentEventsClaims={userShipmentEventsClaims}
            featureFlags={featureFlags}
            addNewFollowUp={addNewFollowUp}
            users={users}
            shipment={shipment}
        />
    );
}

export default ShipmentEvents;
