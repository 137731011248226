import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import claimsType from '../../../../models/entities/claimsType';
import { event } from '../../../../models/entities/common_subentities/event';
import { FollowUp, settings, shipment } from '../../../../models/entities/shipment/shipment';
import { userDetails } from '../../../../state/ducks/users/users';
import clock from '../../../../static/icons/clock.svg';
import CZTooltip from '../../../shared/CZTooltip/CZTooltip';
import FormFieldLabel from '../../../shared/Form/FormFieldLabel';
import Grid from '../../../shared/Grid/Grid';
import { ColumnType, specialColumnType } from '../../../shared/Grid/types/Column';
import { TextArea } from '../../../shared/inputs/base';
import UserDropDownForm from '../../../shared/inputs/form/UserDropDown';
import { default as CustomModal, default as Modal, ModalTriggerProps } from '../../../shared/Modal/Modal';
import Button from '../../../shared/SmallComponents/Button';
import CZDatePicker from '../../../shared/SmallComponents/DatePicker';
import Loader from '../../../shared/SmallComponents/Loader';
import { styled } from '../../../shared/Theme/theme';
import { formMode } from './EventForm/EventFormConnected';

type Props<settingsType> = {
    events?: Array<event>;
    localization: any;
    settings?: settingsType;
    menuType: specialColumnType;
    EventFormConnected: any;
    printMode?: boolean;
    userShipmentEventsClaims?: claimsType;
    featureFlags?: featureFlagsModel;
    users?: userDetails[];
    shipment?: shipment;
    addNewFollowUp?: (shipmentId: string, followUp: FollowUp) => Promise<void>;
};

function Events<settingsType extends settings>({
    events,
    localization,
    menuType,
    EventFormConnected,
    settings,
    printMode,
    userShipmentEventsClaims,
    featureFlags,
    addNewFollowUp,
    shipment,
    users
}: Props<settingsType>) {
    const mockColumns: Array<ColumnType<event>> = [
        {
            field: 'id',
            sortable: false,
            isDisplayed: false
        },
        {
            field: 'eventCode',
            sortable: false,
            isDisplayed: false
        },
        {
            field: 'name',
            width: '150px',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['eventCode'],
            columnType: 'event_name'
        },
        {
            field: 'description',
            sortable: false,
            isDisplayed: true,
            bindedFields: ['eventCode', 'description'],
            columnType: 'event_description'
        },
        {
            field: 'userName',
            width: '120px',
            sortable: false,
            isDisplayed: true
        },
        {
            field: 'eventDate',
            width: '140px',
            sortable: true,
            isDisplayed: true,
            textAlign: 'center',
            columnType: 'date-time'
        },
        {
            field: 'createdAt',
            width: '140px',
            sortable: false,
            isDisplayed: false,
            textAlign: 'center',
            columnType: 'date'
        },
        {
            field: 'comment',
            sortable: false,
            isDisplayed: true
        },
        {
            field: 'displayOnlyForCompanyId',
            width: '40px',
            sortable: false,
            isDisplayed: true,
            renderer: (cellValue: any, bindedData?: Array<any> | null) => {
                if (!cellValue || typeof cellValue !== 'string' || cellValue === '-') return <div></div>;
                return (
                    <CZTooltip text={localization.events.grid.columns.displayOnlyForCompanyIdTooltip}>
                        <span className="material-icons-sharp">security</span>
                    </CZTooltip>
                );
            }
        }
    ];
    const [modalVisible, setVisible] = useState(false);
    const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
    const setInitialValues = () => ({
        todo: '',
        dueDate: new Date(),
        assignedTo: '',
        shipmentId: ''
    });
    const [event, setEvent] = useState({} as event);
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.todo) {
            errors.todo = 'Task is required';
        }
        if (!values.dueDate) {
            errors.dueDate = 'Due Date is required';
        }
        if (!values.assignedTo) {
            errors.assignedTo = 'Assignee is required';
        }
        return errors;
    };
    const submit = async (values: any) => {
        if (addNewFollowUp && shipment) {
            await addNewFollowUp(shipment.id, values);
            setIsFollowUpModalOpen(false);
        }
    };
    return (
        <EventsWrapper>
            {events ? (
                <>
                    <ButtonRow>
                        {!featureFlags?.UNDER_FORWARDER && !printMode && (!userShipmentEventsClaims || userShipmentEventsClaims?.add_event) ? (
                            <CustomModal
                                title={{ icon: clock, text: localization.events.modal.add_title }}
                                Trigger={(props: ModalTriggerProps) => (
                                    <ButtonContainer>
                                        <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                            {localization.events.add_event}
                                        </Button>
                                    </ButtonContainer>
                                )}
                                Content={(
                                    setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                    setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                ) => {
                                    return (
                                        <EventFormConnected
                                            mode={formMode.NEW}
                                            openedFromModal={setVisible ? { setVisible } : undefined}
                                            confirmation={setConfirm ? { setConfirm } : undefined}
                                        />
                                    );
                                }}
                                width="393px"
                            />
                        ) : (
                            <h2>Events</h2>
                        )}
                        {shipment && addNewFollowUp && (
                            <AddFollowUpContainer>
                                <Button
                                    onClick={() => {
                                        setIsFollowUpModalOpen(true);
                                    }}
                                >
                                    Add New Follow-up
                                </Button>
                            </AddFollowUpContainer>
                        )}
                    </ButtonRow>

                    {events.length > 0 ? (
                        <GridWrapper>
                            <Grid<event>
                                columns={mockColumns}
                                data={events}
                                localization={localization.events.grid}
                                onSettingsClick={() => console.log('settings')}
                                onRowMoreOptionsClick={(row) => {
                                    setEvent(row);
                                    setVisible(true);
                                }}
                                menuType={menuType}
                                settings={settings}
                                printMode={printMode}
                                userIdKey="userId"
                            />

                            {modalVisible && (
                                <CustomModal
                                    title={{ icon: clock, text: localization.events.modal.edit_title }}
                                    isOpen={true}
                                    Content={(
                                        setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                                        setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                                    ) => {
                                        return (
                                            <EventFormConnected
                                                mode={formMode.EDIT}
                                                event={event}
                                                openedFromModal={{ setVisible }}
                                                confirmation={setConfirm ? { setConfirm } : undefined}
                                            />
                                        );
                                    }}
                                    onCancelCallback={() => {
                                        setVisible(false);
                                        setEvent({} as event);
                                    }}
                                    width="393px"
                                />
                            )}
                        </GridWrapper>
                    ) : (
                        <EmaptyStateText>{localization.events.empty_state}</EmaptyStateText>
                    )}
                </>
            ) : (
                <Loader />
            )}
            <Modal
                title={{ text: 'Add Follow up Task' }}
                width="600px"
                isOpen={isFollowUpModalOpen}
                onCancelCallback={() => setIsFollowUpModalOpen(false)}
                Content={() => {
                    return (
                        <ModalContent>
                            <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
                                {() => {
                                    return (
                                        <Form>
                                            <FormGroup>
                                                <FormFieldLabel text="Task" />
                                                <Field name="todo">
                                                    {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                                                        <TextArea
                                                            name="todo"
                                                            value={value}
                                                            autoSize={{ minRows: 2 }}
                                                            width="300px"
                                                            onChange={(e: any) => {
                                                                setFieldValue('todo', e);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormFieldLabel text="Due Date" />
                                                <Field name="dueDate">
                                                    {({ field, meta }: FieldProps) => (
                                                        <CZDatePicker
                                                            name="dueDate"
                                                            style={{ width: '300px' }}
                                                            placeHolder="Select Due Date"
                                                            value={field.value}
                                                            error={meta.error}
                                                            touched={meta.touched}
                                                        />
                                                    )}
                                                </Field>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormFieldLabel text="Assign To" />
                                                <UserDropDownForm
                                                    name="assignedTo"
                                                    placeHolder={'Select Assignee'}
                                                    users={users}
                                                    customUsers
                                                    showSearch
                                                />
                                            </FormGroup>
                                            <ButtonContainer>
                                                <Button onClick={() => setIsFollowUpModalOpen(false)}>Cancel</Button>
                                                <Button type="submit">Save</Button>
                                            </ButtonContainer>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </ModalContent>
                    );
                }}
            />
        </EventsWrapper>
    );
}

const GridWrapper = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
`;

const EventsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EmaptyStateText = styled.span`
    color: ${(props) => props.theme.colors.emptyStateText};
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    // padding-top: 8px;
`;
const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 8px;
`;
const AddFollowUpContainer = styled.div`
    margin-left: 10px;
`;
export default Events;

const FormGroup = styled.div`
    margin-bottom: 15px;
    width: 100%;
`;

const ModalContent = styled.div`
    padding: 20px;
`;
