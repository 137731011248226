import React, { useState } from 'react';
import { event } from '../../../../../../models/entities/common_subentities/event';
import { FollowUp, settings, shipment } from '../../../../../../models/entities/shipment/shipment';
import TimeLine from './TimeLine';
import EventFormConnected from '../../../EventForm/EventFormConnected';
import Button from '../../../../../shared/SmallComponents/Button';
import { styled } from '../../../../../shared/Theme/theme';
import CustomModal, { ModalTriggerProps } from '../../../../../shared/Modal/Modal';
import { formMode } from '../../../../commonSubEntities/events/EventForm/EventFormConnected';
import claimsType from '../../../../../../models/entities/claimsType';
import { userDetails } from '../../../../../../state/ducks/users/users';
import Modal from '../../../../../shared/Modal/Modal';
import { Field, FieldProps, Form, Formik } from 'formik';
import FormFieldLabel from '../../../../../shared/Form/FormFieldLabel';
import { TextArea } from '../../../../../shared/inputs/base';
import CZDatePicker from '../../../../../shared/SmallComponents/DatePicker';
import { UserDropDownForm } from '../../../../../shared/inputs/form';

type Props = {
    events?: Array<event>;
    settings?: settings;
    localization: any;
    userShipmentEventsClaims?: claimsType;
    users?: userDetails[];
    shipment?: shipment;
    addNewFollowUp?: (shipmentId: string, followUp: FollowUp) => Promise<void>;
};

function ShipmentExpressEvents({ events, settings, localization, userShipmentEventsClaims, addNewFollowUp, shipment, users }: Props) {
    const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
    const setInitialValues = () => ({
        todo: '',
        dueDate: new Date(),
        assignedTo: '',
        shipmentId: ''
    });
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.todo) {
            errors.todo = 'Task is required';
        }
        if (!values.dueDate) {
            errors.dueDate = 'Due Date is required';
        }
        if (!values.assignedTo) {
            errors.assignedTo = 'Assignee is required';
        }
        return errors;
    };
    const submit = async (values: any) => {
        if (addNewFollowUp && shipment) {
            await addNewFollowUp(shipment.id, values);
            setIsFollowUpModalOpen(false);
        }
    };
    return (
        <EventsWrapper>
            <ButtonRow>
                {!userShipmentEventsClaims || userShipmentEventsClaims?.add_event ? (
                    <CustomModal
                        title={{ text: localization.modal.add_title }}
                        Trigger={(props: ModalTriggerProps) => (
                            <ButtonContainer>
                                <Button onClick={props.onClick} width="auto" buttonType="hollow" padding="4px 12px">
                                    {localization.add_event}
                                </Button>
                            </ButtonContainer>
                        )}
                        Content={(
                            setVisible?: React.Dispatch<React.SetStateAction<boolean>>,
                            setConfirm?: React.Dispatch<React.SetStateAction<boolean>>
                        ) => {
                            return (
                                <EventFormConnected
                                    mode={formMode.NEW}
                                    openedFromModal={setVisible ? { setVisible } : undefined}
                                    confirmation={setConfirm ? { setConfirm } : undefined}
                                />
                            );
                        }}
                        width="393px"
                    />
                ) : (
                    <h2>Events</h2>
                )}
                {shipment && addNewFollowUp && (
                    <AddFollowUpContainer>
                        <Button
                            onClick={() => {
                                setIsFollowUpModalOpen(true);
                            }}
                        >
                            Add New Follow-up
                        </Button>
                    </AddFollowUpContainer>
                )}
            </ButtonRow>
            {
                <TimeLine
                    data={
                        events?.map((item) => {
                            const eventType = `${settings?.eventTypes?.find((e: any) => e.code === item.eventCode)?.description || item.eventCode}, ${
                                localization.updated_by
                            } ${item.userName}, ${item.comment ? 'Comment: ' + item.comment : item.comment}`;
                            return { title: item.description || eventType, date: item.eventDate, isCompleted: true };
                        }) || []
                    }
                />
            }
            <Modal
                title={{ text: 'Add Follow up Task' }}
                width="600px"
                isOpen={isFollowUpModalOpen}
                onCancelCallback={() => setIsFollowUpModalOpen(false)}
                Content={() => {
                    return (
                        <ModalContent>
                            <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
                                {() => {
                                    return (
                                        <Form>
                                            <FormGroup>
                                                <FormFieldLabel text="Task" />
                                                <Field name="todo">
                                                    {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                                                        <TextArea
                                                            name="todo"
                                                            value={value}
                                                            autoSize={{ minRows: 2 }}
                                                            width="300px"
                                                            onChange={(e: any) => {
                                                                setFieldValue('todo', e);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormFieldLabel text="Due Date" />
                                                <Field name="dueDate">
                                                    {({ field, meta }: FieldProps) => (
                                                        <CZDatePicker
                                                            name="dueDate"
                                                            style={{ width: '300px' }}
                                                            placeHolder="Select Due Date"
                                                            value={field.value}
                                                            error={meta.error}
                                                            touched={meta.touched}
                                                        />
                                                    )}
                                                </Field>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormFieldLabel text="Assign To" />
                                                <UserDropDownForm
                                                    name="assignedTo"
                                                    placeHolder={'Select Assignee'}
                                                    users={users}
                                                    customUsers
                                                    showSearch
                                                />
                                            </FormGroup>
                                            <ButtonContainer>
                                                <Button onClick={() => setIsFollowUpModalOpen(false)}>Cancel</Button>
                                                <Button type="submit">Save</Button>
                                            </ButtonContainer>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </ModalContent>
                    );
                }}
            />
        </EventsWrapper>
    );
}

const EventsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 8px;
`;
const AddFollowUpContainer = styled.div`
    margin-left: 10px;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
    width: 100%;
`;

const ModalContent = styled.div`
    padding: 20px;
`;

export default ShipmentExpressEvents;
