import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FollowUp } from '../../../models/entities/shipment/shipment';
import data from '../../../sideEffects/company/settings/mockData';
import { MetaShipment } from '../../../state/ducks/shipments/shipments';
import { userDetails } from '../../../state/ducks/users/users';
import warningBlack from '../../../static/icons/warning-black-18dp.svg';
import warningGreen from '../../../static/icons/warning-green-18dp.svg';
import warningOrange from '../../../static/icons/warning-orange-18dp.svg';
import warningRed from '../../../static/icons/warning-red-18dp.svg';
import FormFieldLabel from '../../shared/Form/FormFieldLabel';
import Grid from '../../shared/Grid/Grid';
import { ColumnType } from '../../shared/Grid/types/Column';
import { Checkbox, TextInput } from '../../shared/inputs/base';
import Button from '../../shared/SmallComponents/Button';
import Tabs from './Tabs';
import FollowUpTaskModal from './FollowUpTaskModal';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import Loader from '../../shared/SmallComponents/Loader';

export interface IFollowUpExtended extends FollowUp {
    state: string;
    previousState: string;
    shipmentNumber: string;
}

type Props = {
    followUps: IFollowUpExtended[];
    addNewFollowUp: (shipmentId: string, followUp: FollowUp) => Promise<void>;
    updateFollowUp: (shipmentId: string, followUp: FollowUp) => Promise<void>;
    updateFollowUpCompletionStatus: (shipmentId: string, followUpId: number, isCompleted: boolean) => Promise<void>;
    deleteFollowUp: (shipmentId: string, followUpId: number) => Promise<void>;
    users: userDetails[];
    metaShipments: MetaShipment[];
    setShowAllFollowUps: (showAllFollowUps: boolean) => void;
    showAllFollowUps: boolean;
    currentUserId: string;
    setLastVisitShipmentId: (shipmentId: string) => void;
    lastVisitShipmentId?: string;
};

const FollowUpTab = ({
    metaShipments,
    followUps,
    addNewFollowUp,
    updateFollowUp,
    updateFollowUpCompletionStatus,
    deleteFollowUp,
    users,
    setShowAllFollowUps,
    showAllFollowUps,
    currentUserId,
    setLastVisitShipmentId,
    lastVisitShipmentId
}: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedTask, setSelectedTask] = useState<IFollowUpExtended | null>(null);
    const [activeTabKey, setActiveTabKey] = useState('active'); // 'active' or 'completed'
    const history = useHistory();
    const [textFilterValue, setTextFilterValue] = useState<string>('');
    const [isTypingTextFilter, setIsTypingTextFilter] = useState(false);
    // Rest of the columns definition remains the same
    const followupGridColumns: ColumnType<IFollowUpExtended>[] = [
        {
            title: 'Shipment Number',
            field: 'shipmentNumber',
            sortable: true,
            isDisplayed: true,
            width: '150px',
            bindedFields: ['shipmentId', 'shipmentNumber', 'id'],
            renderer: (cellValue: any, bindedData?: Array<any> | null) => (
                <Link
                    to={{}}
                    onClick={() => {
                        setLastVisitShipmentId(bindedData?.[2]);
                        history.push(`/shipments/${bindedData?.[0]}`);
                    }}
                >
                    {bindedData?.[1]}
                </Link>
            )
        },
        {
            title: 'To Do',
            field: 'todo',
            sortable: true,
            isDisplayed: true,
            width: '400px'
        },
        {
            title: 'Current State',
            field: 'state',
            sortable: true,
            isDisplayed: true,
            width: '150px'
        },
        {
            title: 'Previous State',
            field: 'previousState',
            sortable: true,
            isDisplayed: true,
            width: '150px'
        },
        {
            title: 'Shipper',
            field: 'shipper',
            sortable: true,
            isDisplayed: true,
            width: '150px'
        },
        {
            title: 'Consignee',
            field: 'consignee',
            sortable: true,
            isDisplayed: true,
            width: '150px'
        },
        {
            title: 'Cargo Owner',
            field: 'cargoOwner',
            sortable: true,
            isDisplayed: true,
            width: '150px'
        },
        {
            title: 'Tracking ID',
            field: 'trackingId',
            sortable: true,
            isDisplayed: true,
            width: '150px'
        },
        {
            title: 'Due Date',
            field: 'dueDate',
            sortable: true,
            isDisplayed: true,
            width: '150px',
            columnType: 'date-time'
        },
        {
            title: 'Assigned To',
            field: 'assignedTo',
            sortable: true,
            isDisplayed: true,
            width: '200px'
        },
        {
            title: 'Risk',
            field: 'shipmentNumber',
            sortable: true,
            isDisplayed: true,
            width: '100px',
            bindedFields: ['shipmentId', 'shipmentNumber', 'dueDate', 'isCompleted'],
            renderer: (cellValue: any, bindedData?: Array<any> | null) => {
                const currentDate = moment();
                const dueDate = moment(bindedData?.[2]);
                const diff = dueDate.diff(currentDate, 'days');
                const isCompleted = bindedData?.[3] as boolean;
                let src = warningGreen;
                if (!isCompleted) {
                    if (diff < 0) {
                        src = warningBlack;
                    } else if (diff < 1) {
                        src = warningRed;
                    } else if (diff < 2) {
                        src = warningOrange;
                    } else {
                        src = warningGreen;
                    }
                }
                return <Image src={src} width={20} />;
            }
        },
        {
            title: 'Completed',
            field: 'isCompleted',
            sortable: true,
            isDisplayed: true,
            width: '120px',
            bindedFields: ['id', 'isCompleted', 'shipmentId'],
            renderer(cellValue, bindedData) {
                return (
                    <Checkbox
                        checked={(bindedData as any)[1] as boolean}
                        onChange={(e) => updateFollowUpCompletionStatus((bindedData as any)[2], (bindedData as any)[0], e.target.checked)}
                    />
                );
            }
        },
        {
            title: 'Actions',
            field: 'id',
            sortable: false,
            isDisplayed: true,
            width: '200px',
            bindedFields: ['id', 'shipmentId', 'todo', 'dueDate', 'assignedTo', 'isCompleted'],
            renderer: (cellValue, bindedData) => {
                // if (!bindedData) return null;

                const handleEdit = () => {
                    const followUp = followUps.find((f) => f.id === bindedData?.[0]);
                    if (followUp) {
                        setSelectedTask(followUp);
                        setIsEditMode(true);
                        setIsModalOpen(true);
                    }
                };

                return (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Button onClick={handleEdit}>
                            <EditOutlined />
                        </Button>
                        <Button
                            onClick={() => {
                                if (window.confirm('Are you sure you want to delete this follow-up task?')) {
                                    deleteFollowUp(bindedData?.[1], bindedData?.[0]);
                                }
                            }}
                        >
                            <DeleteFilled />
                        </Button>
                    </div>
                );
            }
        }
    ];

    const [manipulatedFollowUps, setManipulatedFollowUps] = useState<IFollowUpExtended[]>([]);
    const [activeFollowUps, setActiveFollowUps] = useState<IFollowUpExtended[]>([]);
    const [completedFollowUps, setCompletedFollowUps] = useState<IFollowUpExtended[]>([]);

    const handleOpenAddModal = () => {
        setIsEditMode(false);
        setSelectedTask(null);
        setIsModalOpen(true);
    };

    const handleSubmit = async (values: any) => {
        if (isEditMode && selectedTask) {
            await updateFollowUp(values.shipmentId, {
                ...values,
                id: selectedTask.id,
                state: selectedTask.state,
                previousState: selectedTask.previousState
            });
        } else {
            await addNewFollowUp(values.shipmentId, values);
        }
    };

    // Process the follow-ups and separate active and completed tasks
    useEffect(() => {
        const userMap: Map<string, userDetails> = new Map();

        users.forEach((user) => {
            userMap.set(user.id, user);
        });

        const eventsMap: Map<string, string> = new Map();
        data.en.state.forEach((state) => {
            eventsMap.set(state.code, state.name);
        });

        const processed = followUps
            .map((followUp) => {
                const user = userMap.get(followUp.assignedTo);
                const assignedTo = user ? `${user.firstName} ${user.lastName}` : 'N/A';
                const followUpExtended: IFollowUpExtended = {
                    ...followUp,
                    state: eventsMap.get(followUp.state) || 'N/A',
                    previousState: eventsMap.get(followUp.previousState) || 'N/A',
                    assignedTo
                };
                return followUpExtended;
            })
            .filter((followUp) => {
                if (!textFilterValue) return true;
                return followUp.shipmentNumber.toLowerCase().includes(textFilterValue.toLowerCase());
            });

        setManipulatedFollowUps(processed);

        // Split into active and completed
        const active = processed.filter((task) => !task.isCompleted);
        const completed = processed.filter((task) => task.isCompleted);

        setActiveFollowUps(active);
        setCompletedFollowUps(completed);
    }, [followUps, users, textFilterValue]);

    const handleTextFilterChange = (value: string) => {
        setIsTypingTextFilter(true);
        setTextFilterValue(value);

        // Debounce the typing indicator
        setTimeout(() => {
            setIsTypingTextFilter(false);
        }, 300);
    };

    const renderTabContent = () => {
        const currentData = activeTabKey === 'active' ? activeFollowUps : completedFollowUps;

        if (currentData.length === 0) {
            return (
                <CenterContainer>
                    <FormFieldLabel text={`No ${activeTabKey === 'active' ? 'active' : 'completed'} follow-up tasks found`} />
                </CenterContainer>
            );
        }

        return (
            <Grid<IFollowUpExtended>
                columns={followupGridColumns}
                data={currentData}
                idKey="id"
                lastVisitId={lastVisitShipmentId}
                emptyStateText={`No ${activeTabKey === 'active' ? 'active' : 'completed'} follow-up tasks found`}
            />
        );
    };

    const tabItems = [
        {
            key: 'active',
            label: `Active Tasks (${activeFollowUps.length})`
        },
        {
            key: 'completed',
            label: `Completed Tasks (${completedFollowUps.length})`
        }
    ];
    const onAssignedToMeOnlyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowAllFollowUps(e.target.checked);
    };

    return (
        <PageWrapper>
            <HeaderContainer>
                <h2>Follow-up Tasks</h2>
                <ButtonGroup>
                    <TextInput
                        style={{
                            height: '30px',
                            margin: '4px',
                            width: 'auto'
                        }}
                        allowClear={true}
                        onChange={handleTextFilterChange}
                        placeHolder="Search by Shipment No."
                        value={textFilterValue || ''}
                        suffix={isTypingTextFilter && <Loader wrapperWidth="auto" showText={false} marginTop="0" width="15px" />}
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={showAllFollowUps} onChange={onAssignedToMeOnlyChange} />}
                            label="Show all tasks"
                        />
                    </FormGroup>
                    <Button onClick={handleOpenAddModal}>Add New Follow-up</Button>
                </ButtonGroup>
            </HeaderContainer>

            <TabsContainer>
                <Tabs items={tabItems} activeKey={activeTabKey} onChange={(key) => setActiveTabKey(key)} />
            </TabsContainer>

            <GridWrapper>{renderTabContent()}</GridWrapper>

            <FollowUpTaskModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleSubmit}
                initialValues={
                    selectedTask
                        ? {
                              id: selectedTask.id,
                              todo: selectedTask.todo,
                              dueDate: selectedTask.dueDate,
                              assignedTo: selectedTask.assignedTo,
                              shipmentId: selectedTask.shipmentId,
                              isCompleted: selectedTask.isCompleted
                          }
                        : undefined
                }
                users={users}
                metaShipments={metaShipments}
                isEdit={isEditMode}
                currentUserId={currentUserId}
            />
        </PageWrapper>
    );
};

export default FollowUpTab;

const PageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const ButtonGroup = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
const TabsContainer = styled.div`
    width: 100%;
`;

const GridWrapper = styled.div`
    flex: 1;
    overflow: auto;
    width: 100%;
    z-index: 1;
`;

const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

interface ImageProps {
    width?: number;
}

const Image = styled.img<ImageProps>`
    ${(props) => (props.width ? 'width:' + props.width + 'px' : 'width:20px')};
`;
